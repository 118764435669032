import React from "react";
import { Box } from "@chakra-ui/react";

const tallyMarks = require("tally-marks");

interface ErrorProps {
  sound: string;
  count: number;
}

const Error = ({ sound, count }: ErrorProps) => {
  return <Box color="error">{sound + ": " + tallyMarks(count)}</Box>;
};

export default Error;
