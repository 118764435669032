import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner, Stack, useToast } from '@chakra-ui/react';
import axios from '../axios-config';
import AnalysisStepper from '../components/Test/AnalysisStepper';
import { ResultCreate } from '../models/result-overview';
import { ListWord, TestWord } from '../models/word';
import { useAddResultMutation, useFetchListQuery } from '../redux/apiSlice';

const AnalysisPage: FC = () => {
  const [wordIndex, setWordIndex] = useState(0);
  const [words, setWords] = useState<TestWord[]>([]);
  const [listName, setListName] = useState<string>('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { listId, clientId } = useParams<{
    listId: string;
    clientId: string;
  }>();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const toast = useToast();
  const { data: listData, isLoading: listIsLoading } = useFetchListQuery(
    parseInt(listId),
  );
  const [addResult, { isLoading: resultIsLoading }] = useAddResultMutation();

  const submitResults = async (list_name: string, words: TestWord[]) => {
    const testResults = { ...words };
    const body = {
      clientId: parseInt(clientId),
      list_name: list_name,
      results: testResults,
    } as ResultCreate;
    const response = await addResult(body).unwrap();
    history.push(`/client/${clientId}/result/${response.resultId}`);
  };

  const parseCorrectSounds = (word: ListWord) => {
    let letters = word.transcription;
    if (word.syllable_position == null) {
      // One syllable words
      return [letters];
    } else {
      //Multi syllable words
      let syllable_position = word.syllable_position.map((num) => num); // allows for more than 2 syllables
      syllable_position.push(letters.length - 1);
      let syllables = [];
      let i = 0;
      let pos;
      for (pos of syllable_position) {
        let syllable = [];
        for (i; i <= pos; i++) {
          syllable.push(letters[i]);
        }
        syllables.push(syllable);
        i = pos + 1;
      }
      return syllables;
    }
  };

  const formatAssessmentWords = (list_words: ListWord[]): TestWord[] => {
    return list_words.map((word: ListWord) => {
      const correctSounds = parseCorrectSounds(word);
      return {
        transcriptionID: word.word_id,
        word: word.word,
        correctSounds,
        selectedSounds: [...correctSounds],
        consonants: {
          initial: word.initial_consonant,
          medial: word.medial_consonant,
          final: word.final_consonant,
        },
        beenTested: false,
      };
    });
  };

  useEffect(() => {
    if (listData) {
      setListName(listData.name);
      const test_words = formatAssessmentWords(listData.words);
      console.log(test_words);
      setWords(test_words);
      setIsLoading(false);
    }
  }, [listData]);

  if (isLoading) {
    return (
      <div className="text-center h-100">
        <Spinner size="xl" style={{ marginTop: '40vh' }} />
      </div>
    );
  }
  return (
    <Stack pt={65}>
      <AnalysisStepper
        list_name={listName}
        analysis_words={words}
        submitResults={submitResults}
      />
    </Stack>
  );
};

export default AnalysisPage;
