import { CheckIcon, EditIcon } from '@chakra-ui/icons';
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import apple_transcription_example from '../../../assests/images/apple_transcription_example.png';
import { useScreenWidth } from '../../../utils/screenHooks';
import InfoPopover from '../../InfoPopover';
import { NewSound } from '../NewSound';
import { TranscriptionSound } from '../TranscriptionInput';
import CombinedLayout from './CombinedLayout';
import TabbedLayout from './TabbedLayout';
import { TranscriptionKey } from './TranscriptionKey';
import VowelLayout from './VowelLayout';

interface TranscriptionKeyboardProps {
  onAddSound: (sound: string) => void;
  onRemoveSound: () => void;
  sounds: TranscriptionSound[];
  toggleSyllableStart: (sound: TranscriptionSound) => void;
}
const TranscriptionKeyboard: FC<TranscriptionKeyboardProps> = ({
  onAddSound,
  onRemoveSound,
  sounds,
  toggleSyllableStart,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);
  /* const [orientation, setOrientation] = useState(getOrientation());
   * const [screenWidth, setScreenWidth] = useState(Math.round(window.innerWidth)); */
  let horWidth = Math.round(window.innerWidth);
  let verWidth = Math.round(window.innerHeight);
  if (horWidth < verWidth) {
    verWidth = horWidth;
    horWidth = Math.round(window.innerHeight);
  }

  const [orientation, screenWidth] = useScreenWidth();

  return (
    <>
      <HStack
        onClick={onOpen}
        border="1px"
        borderColor="gray.200"
        borderRadius="md"
        minHeight={'42px'}
        width={'full'}
        py={'8px'}
        px={'15px'}
      >
        {sounds?.map((sound, index) => (
          <div key={index}>
            {sound.sound}
            {sound.syllableStart && ' .'}
          </div>
        ))}
      </HStack>
      <Drawer
        isOpen={isOpen}
        placement="bottom"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            Transcription Keyboard
            <InfoPopover
              title="Multi-syllable Words"
              message="For multi-syllable words, select the sound at the end of each syllable, highlighting it green. The word 'apple' would look like:"
              image={apple_transcription_example}
            />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <IconButton
                aria-label="close transcription keyboard"
                onClick={onClose}
                icon={<CheckIcon />}
              />
            </Box>
            <HStack
              justifyContent={'center'}
              maxW={'90vw'}
              overflowX={'auto'}
              height={130}
            >
              {sounds?.map((sound) => (
                <NewSound
                  key={sound.index}
                  transcriptionSound={sound}
                  onToggle={toggleSyllableStart}
                />
              ))}
            </HStack>
          </DrawerHeader>

          <DrawerBody paddingX={0}>
            {orientation === 'portrait' && screenWidth < 700 ? (
              <TabbedLayout
                onAddSound={onAddSound}
                onRemoveSound={onRemoveSound}
                screenWidth={screenWidth}
              />
            ) : (
              <CombinedLayout
                onAddSound={onAddSound}
                onRemoveSound={onRemoveSound}
                screenWidth={screenWidth}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default TranscriptionKeyboard;
