import { differenceInMonths, differenceInYears } from 'date-fns';

export default function getAge(dateOfBirth: string) {
  var dateParts = dateOfBirth.split('/');

  // month is 0-based, that's why we need dataParts[1] - 1
  var dateObject = new Date(
    +dateParts[2],
    parseInt(dateParts[1]) - 1,
    +dateParts[0],
  );
  const years = differenceInYears(new Date(), dateObject);
  const months = differenceInMonths(new Date(), dateObject) % 12;
  return `${years} Year${years === 1 ? '' : 's'}, ${months} Month${
    months === 1 ? '' : 's'
  }`;
}
