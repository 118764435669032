import React, { useState } from 'react';
import { Sound } from './Sound';
import { SoundButton } from './SoundButton';
import { Box } from '@chakra-ui/react';

const emptyCharacter = 'ø';

interface SyllableProps {
  syllable: string[];
  position: number;
  isPartOfMultiSyllableWord: boolean;
  onChange: (newSelection: string[], position: number) => void;
  selected: string[];
}

const Syllable = (props: SyllableProps) => {
  const { selected, position, syllable, onChange, isPartOfMultiSyllableWord } =
    props;
  const [prevSelected, setPrevSelected] = useState(syllable); // Used to remember syllable after Clear

  const isEmpty = () => selected.every((item) => item === emptyCharacter);

  const changeSelected = (value: string, pos: number) => {
    let _selected = [...props.selected];
    _selected[pos] = value;
    onChange(_selected, position);
    setPrevSelected([..._selected]);
  };

  const onClearSyllable = () => {
    const selection = isEmpty()
      ? prevSelected
      : new Array(syllable.length).fill(emptyCharacter);
    onChange(selection, position);
  };

  let emptySyllableButton = (
    <Box
      height={{
        base: 'calc(80px + 58px)',
        md: 'calc(90px + 58px)',
        lg: 'calc(100px + 58px)',
      }}
    />
  );
  if (isPartOfMultiSyllableWord && !isEmpty()) {
    emptySyllableButton = (
      <div className="row mx-0 my-4 empty justify-content-center">
        <SoundButton
          selected={isEmpty()}
          sound={emptyCharacter.toUpperCase()}
          onSelected={onClearSyllable}
          colour="black"
        />
      </div>
    );
  }

  return (
    <div className="col">
      {emptySyllableButton}
      <div className="row justify-content-center">
        {syllable.map((sound: string, i: number) => (
          <div key={i} className="col-md col-sm-12 mb-2 mb-md-auto px-0">
            <Sound
              selectedSound={selected[i]}
              correctSound={sound}
              pos={i}
              onChange={changeSelected}
              isEmpty={isEmpty}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Syllable;
