import React from "react";
import {
  Box,
  Flex,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Tr,
} from "@chakra-ui/react";
import {
  PlaceVoiceManner,
  PVMCategory,
  PVMConsonantTypes,
} from "../../models/place-voice-manner";
import ConsonantPlace from "./ConsonantPlace";
import { voiced } from "./Consonant";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import "../../stylesheets/scroll-shadow.css";

interface PVMTableProps {
  placeVoiceManner: PlaceVoiceManner;
  consonantTypeResults: {
    [consonantCategory: string]: {
      totalCorrect: number;
      totalIncorrect: number;
    };
  };
  consonantTypes: PVMConsonantTypes;
}

const HozTable = ({
  consonantTypes,
  consonantTypeResults,
  placeVoiceManner,
}: PVMTableProps) => {
  const totalCorrect = Object.entries(consonantTypeResults)
    .map((category) => {
      return category[1].totalCorrect;
    })
    .reduce((a, b) => a + b, 0);
  const totalIncorrect = Object.entries(consonantTypeResults)
    .map((category) => {
      return category[1].totalIncorrect;
    })
    .reduce((a, b) => a + b, 0);

  return (
    <TableContainer overflowX="auto" className="scroll-shadow">
      <Table variant="simple" size="md" width="auto">
        <Tbody>
          <Tr>
            <Td bg="dark_shade" pr="3">
              <Flex flex="1">
                <Box textAlign="left" textTransform="capitalize">
                  Total
                </Box>
                <Spacer minW="10px" />
                <Box textAlign="right">
                  {totalCorrect}{" "}
                  <CheckIcon color="success" boxSize={4} mr={3} mb={1} />
                  {totalIncorrect}{" "}
                  <CloseIcon color="error" boxSize={3} mr={3} mb={1} />
                </Box>
              </Flex>
            </Td>
            {Object.entries(consonantTypes).map((category, categoryIndex) => {
              const sounds = category[1];
              const categoryName: PVMCategory = category[0] as PVMCategory;
              const totalCorrect =
                consonantTypeResults[categoryName].totalCorrect;
              const totalIncorrect =
                consonantTypeResults[categoryName].totalIncorrect;
              return (
                <Td
                  colSpan={sounds.length}
                  bg="dark_shade"
                  borderLeft="1px solid white"
                  pr="3"
                  key={"header" + categoryIndex + categoryName}
                >
                  <Flex flex="1">
                    <Box textAlign="left" textTransform="capitalize">
                      {categoryName}
                    </Box>
                    <Spacer minW="10px" />
                    <Box textAlign="right">
                      {totalCorrect}{" "}
                      <CheckIcon color="success" boxSize={4} mr={3} mb={1} />
                      {totalIncorrect}{" "}
                      <CloseIcon color="error" boxSize={3} mr={3} mb={1} />
                    </Box>
                  </Flex>
                </Td>
              );
            })}
          </Tr>
          <Tr>
            <Th borderY="1px" borderColor="gray.300">
              Consonant
            </Th>
            {Object.entries(consonantTypes).map((category, categoryIndex) => {
              const sounds = category[1];
              return sounds.map((sound: any, soundIndex: number) => {
                const isVoiced = voiced.includes(sound);
                return (
                  <Td
                    key={"sound" + categoryIndex + soundIndex}
                    bg={isVoiced ? "light_shade" : ""}
                    borderY="1px"
                    borderColor="gray.300"
                    textAlign="center"
                  >
                    {sound}
                  </Td>
                );
              });
            })}
          </Tr>
          <Tr>
            <Th borderY="1px" borderColor="gray.300">
              Initial
            </Th>
            {Object.entries(consonantTypes).map((category, categoryIndex) => {
              const categoryName: PVMCategory = category[0] as PVMCategory;
              const sounds = category[1];
              return sounds.map((sound: any, soundIndex: number) => {
                const isVoiced = voiced.includes(sound);
                const results = placeVoiceManner[categoryName];
                return (
                  <Td
                    key={"initial" + categoryIndex + soundIndex}
                    bg={isVoiced ? "light_shade" : ""}
                    borderY="1px"
                    borderColor="gray.300"
                  >
                    {Object.keys(results).includes(sound) ? (
                      <ConsonantPlace
                        consonant={results[sound].initial_consonant}
                      />
                    ) : null}
                  </Td>
                );
              });
            })}
          </Tr>
          <Tr>
            <Th borderY="1px" borderColor="gray.300">
              Medial
            </Th>
            {Object.entries(consonantTypes).map((category, categoryIndex) => {
              const categoryName: PVMCategory = category[0] as PVMCategory;
              const sounds = category[1];
              return sounds.map((sound: any, soundIndex: number) => {
                const isVoiced = voiced.includes(sound);
                const results = placeVoiceManner[categoryName];
                return (
                  <Td
                    key={"medial" + categoryIndex + soundIndex}
                    bg={isVoiced ? "light_shade" : ""}
                    borderY="1px"
                    borderColor="gray.300"
                  >
                    {Object.keys(results).includes(sound) ? (
                      <ConsonantPlace
                        consonant={results[sound].medial_consonant}
                      />
                    ) : null}
                  </Td>
                );
              });
            })}
          </Tr>
          <Tr>
            <Th borderY="1px" borderColor="gray.300">
              Final
            </Th>
            {Object.entries(consonantTypes).map((category, categoryIndex) => {
              const categoryName: PVMCategory = category[0] as PVMCategory;
              const sounds = category[1];
              return sounds.map((sound: any, soundIndex: number) => {
                const isVoiced = voiced.includes(sound);
                const results = placeVoiceManner[categoryName];
                return (
                  <Td
                    key={"final" + categoryIndex + soundIndex}
                    bg={isVoiced ? "light_shade" : ""}
                    borderY="1px"
                    borderColor="gray.300"
                  >
                    {Object.keys(results).includes(sound) ? (
                      <ConsonantPlace
                        consonant={results[sound].final_consonant}
                      />
                    ) : null}
                  </Td>
                );
              });
            })}
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default HozTable;
