import React from "react";
import { ErrorPattern as IErrorPattern } from "../../models/error-pattern";
import { PatternAnalysisApiResponse } from "../../models/error-pattern-analysis";
import ErrorPattern from "./ErrorPattern";
import { Heading, Stack, Text } from "@chakra-ui/react";

interface PatternAnalysisProps {
  patternAnalysis: PatternAnalysisApiResponse;
}

const PatternAnalysis = ({ patternAnalysis }: PatternAnalysisProps) => {
  const errors: IErrorPattern[] = [];
  for (const [key, value] of Object.entries(patternAnalysis)) {
    errors.push({
      name: key,
      occurrences: value.occurrences,
      opportunities: value.opportunities,
    });
  }

  const errorPatternsToDisplay = errors
    .filter((errorPattern) => errorPattern.occurrences > 0)
    .sort(
      (a, b) =>
        b.occurrences / b.opportunities - a.occurrences / a.opportunities
    );

  return (
    <Stack p="4">
      <Heading>Pattern Analysis</Heading>
      {errorPatternsToDisplay.length === 0 && <Text>No error patterns</Text>}
      {errorPatternsToDisplay.map((errorPattern) => (
        <ErrorPattern key={errorPattern.name} errorPattern={errorPattern} />
      ))}
    </Stack>
  );
};

export default PatternAnalysis;
