import React from 'react';
import { Button } from '@chakra-ui/react';

interface SoundButtonProps {
  selected: boolean;
  sound: string;
  onSelected: (selectedCharacter: string) => void;
  colour: string;
}

export const SoundButton = (props: SoundButtonProps) => {
  const { selected, onSelected, colour, sound } = props;
  const handleClick = () => {
    onSelected(sound);
  };

  return (
    <Button
      onClick={handleClick}
      borderRadius={'20%'}
      borderColor={colour}
      borderWidth={'4px'}
      backgroundColor={'transparent'}
      variant={selected ? 'solid' : 'outline'}
      height={{ base: '80px', md: '90px', lg: '100px' }}
      width={{ base: '80px', md: '90px', lg: '100px' }}
      color={colour}
      margin={'5px'}
      fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}
      _active={{
        bg: selected ? colour : 'transparent',
        color: selected ? 'white' : colour,
      }}
      isActive={selected}
    >
      {sound}
    </Button>
  );
};
