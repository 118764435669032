import React, { FC } from 'react';
import {
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import AssessmentWordsTab from './AssessmentWordsTab';
import CustomListTab from './CustomListTab';

const AssessmentListSmallScreen: FC = () => {
  return (
    <div>
      <Tabs isFitted variant="solid-rounded" colorScheme={'orange'}>
        <TabPanels>
          <TabPanel pt={'90px'} px={0}>
            <AssessmentWordsTab />
          </TabPanel>
          <TabPanel pt={'90px'} px={0}>
            <CustomListTab />
          </TabPanel>
        </TabPanels>
        <TabList pos={'fixed'} bg="background" top={'60px'} w={'100%'} p={5}>
          <HStack w="100%" bg="#cccccc" p="1" borderRadius="full">
            <Tab>Add Words</Tab>
            <Tab>Your List</Tab>
          </HStack>
        </TabList>
      </Tabs>
    </div>
  );
};

export default AssessmentListSmallScreen;
