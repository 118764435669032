import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner, Stack } from '@chakra-ui/react';
import axios from '../axios-config';
import AnalysisStepper from '../components/Test/AnalysisStepper';
import { ListWord, TestWord } from '../models/word';
import { useFetchResultQuery } from '../redux/apiSlice';
import { useUpdateResultMutation } from '../redux/apiSlice';

const EditResultsPage: FC = () => {
  const [wordIndex, setWordIndex] = useState(0);
  const [words, setWords] = useState<TestWord[]>([]);
  const [listName, setListName] = useState<string>('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { clientId, resultId } = useParams<{
    clientId: string;
    resultId: string;
  }>();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { data, isLoading: resultIsLoading } = useFetchResultQuery({
    clientId: parseInt(clientId),
    resultId: parseInt(resultId),
  });
  const [updateResult] = useUpdateResultMutation();

  const submitResults = async (list_name: string, words: TestWord[]) => {
    const testResults = { ...words };
    const body = {
      clientId: parseInt(clientId),
      resultId: parseInt(resultId),
      list_name: list_name,
      results: testResults,
    };
    await updateResult(body);
    history.push(`/client/${clientId}/result/${resultId}`);
  };

  const formatResultWords = (soundResults: {
    [key: string]: TestWord;
  }): TestWord[] => {
    return Object.keys(soundResults)
      .sort((a, b) => parseInt(a) - parseInt(b))
      .map((key) => JSON.parse(JSON.stringify(soundResults[key])));
  };

  useEffect(() => {
    if (data) {
      const words = formatResultWords(data.soundResults);
      setWords(words);
      setIsLoading(false);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="text-center h-100">
        <Spinner size="xl" style={{ marginTop: '40vh' }} />
      </div>
    );
  }
  return (
    <Stack pt={65}>
      <AnalysisStepper
        list_name={listName}
        analysis_words={words}
        submitResults={submitResults}
      />
    </Stack>
  );
};

export default EditResultsPage;
