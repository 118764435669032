import React, { FC, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ArrowUpDownIcon,
  ChevronDownIcon,
  DragHandleIcon,
} from '@chakra-ui/icons';
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Transcription, Word } from '../../models/word';
import { speechyApi } from '../../redux/apiSlice';
import { AppDispatch } from '../../redux/store';
import getTranscription from '../../utils/getTranscription';
import AddTranscriptionModal from './AddTranscriptionModal';

export interface WordMenuOption {
  label: string;
  onSelect: (word: Word) => void;
  icon: JSX.Element;
  variant?: string;
}

interface WordTableProps {
  words: Word[];
  filter: string;
  loading: boolean;
  setWords?: (words: Word[]) => void;
  droppableId: string;
  menuOptions?: WordMenuOption[];
  editable?: boolean;
  height?: string;
  editingListIDs?: number[];
}

export const selectedTranscription = (word: Word) => {
  return word.transcriptions[word.selected_transcription];
};

const WordTable: FC<WordTableProps> = ({
  words,
  filter,
  loading,
  setWords,
  menuOptions,
  droppableId,
  editable,
  height,
  editingListIDs,
}) => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const { updateQueryData } = speechyApi.util;

  const filteredWords = words.filter((l) =>
    l.word.toLowerCase().includes(filter.toLowerCase()),
  );

  const updateSelectedTranscription = (
    word: Word,
    transcription: Transcription,
  ) => {
    const transcriptionIndex = word.transcriptions.indexOf(transcription);
    const foundWordIndex = words.indexOf(word);
    const wordsCopy = JSON.parse(JSON.stringify(words));
    wordsCopy.splice(foundWordIndex, 1, {
      ...word,
      selected_transcription: transcriptionIndex,
    });
    if (setWords) setWords(wordsCopy);
  };

  const onDragEnd = (evt: any) => {
    console.log(evt);
    const word_id = parseInt(evt.draggableId);
    const index = filteredWords.findIndex((w) => w.word_id === word_id);
    const word = filteredWords.splice(index, 1)[0];
    filteredWords.splice(evt.destination.index, 0, word);
    if (setWords) {
      setWords(filteredWords);
    }
  };

  const onDragStart = (evt: any) => {
    /* console.log(evt); */
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <TableContainer
          height={height ?? 'fit'}
          w="100%"
          overflowY="auto"
          overflowX="auto"
          sx={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'gray.200',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'gray.500',
              borderRadius: 'full',
            },
          }}
        >
          <Table
            size="md"
            variant={!editable ? 'striped' : 'simple'}
            colorScheme="orange"
          >
            {loading && (
              <TableCaption>
                <Spinner size="xl" style={{ marginTop: '40vh' }} />
              </TableCaption>
            )}
            {filteredWords.length === 0 && !loading && (
              <TableCaption>No words to show in this list</TableCaption>
            )}
            <Droppable droppableId={droppableId} type="word">
              {(provided, snapshot) => (
                <Tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {filteredWords?.map((w, index) => (
                    <Draggable
                      isDragDisabled={!editable}
                      key={w.word_id}
                      draggableId={w.word_id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Tr
                          key={w.word_id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Td
                            maxW={{ base: '130px', sm: '160px', xl: '500px' }}
                            overflowX={'auto'}
                          >
                            {editable && (
                              <strong style={{ marginRight: '20px' }}>
                                {index + 1}
                              </strong>
                            )}
                            {w.word}
                          </Td>
                          <Td
                            maxW={{ base: '160px', sm: '190px', xl: '500px' }}
                            overflowX={'auto'}
                          >
                            <Menu autoSelect={false}>
                              <MenuButton
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                              >
                                {getTranscription(
                                  selectedTranscription(w).transcription,
                                  selectedTranscription(w).syllable_position,
                                )}
                              </MenuButton>
                              <Portal>
                                <MenuList>
                                  {w.transcriptions.map((t) => (
                                    <MenuItem
                                      key={t.transcription_id}
                                      onClick={() =>
                                        updateSelectedTranscription(w, t)
                                      }
                                    >
                                      {getTranscription(
                                        t.transcription,
                                        t.syllable_position,
                                      )}
                                    </MenuItem>
                                  ))}
                                  <AddTranscriptionModal
                                    spellingInput={w.word}
                                  />
                                </MenuList>
                              </Portal>
                            </Menu>
                          </Td>
                          {!!menuOptions && (
                            <Td>
                              <HStack>
                                {!!editingListIDs &&
                                editingListIDs.includes(w.word_id) ? (
                                  <IconButton
                                    key={menuOptions[1].label}
                                    onClick={() => menuOptions[1].onSelect(w)}
                                    variant={
                                      menuOptions[1].variant ?? 'primary'
                                    }
                                    icon={menuOptions[1].icon}
                                    aria-label={menuOptions[1].label}
                                    size={'sm'}
                                  />
                                ) : (
                                  <IconButton
                                    key={menuOptions[0].label}
                                    onClick={() => menuOptions[0].onSelect(w)}
                                    variant={
                                      menuOptions[0].variant ?? 'primary'
                                    }
                                    icon={menuOptions[0].icon}
                                    aria-label={menuOptions[0].label}
                                    size={'sm'}
                                  />
                                )}
                              </HStack>
                            </Td>
                          )}
                        </Tr>
                      )}
                    </Draggable>
                  ))}
                </Tbody>
              )}
            </Droppable>
          </Table>
        </TableContainer>
      </DragDropContext>
    </>
  );
};

export default WordTable;
