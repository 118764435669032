import React from 'react';
import { SoundButton } from '../Test/SoundButton';
import { TranscriptionSound } from './TranscriptionInput';

interface NewSoundProps {
  transcriptionSound: TranscriptionSound;
  onToggle: (sound: TranscriptionSound) => void;
}

export const NewSound = (props: NewSoundProps) => {
  const { transcriptionSound } = props;

  const toggleSyllable = () => {
    props.onToggle({
      index: transcriptionSound.index,
      sound: transcriptionSound.sound,
      syllableStart: !transcriptionSound.syllableStart,
    });
  };

  return (
    <SoundButton
      selected={transcriptionSound.syllableStart}
      sound={transcriptionSound.sound}
      onSelected={(char) => toggleSyllable()}
      colour={'green'}
    />
  );
};
