import React from "react";
import { Heading, Stack, Text } from "@chakra-ui/react";
import getPercentage from "../../utils/getPercentage";

interface TestOverviewProps {
  consonantTypeResults: {
    [consonantCategory: string]: {
      totalCorrect: number;
      totalIncorrect: number;
    };
  };
}

const TestOverview = ({ consonantTypeResults }: TestOverviewProps) => {
  const totalCorrect = Object.entries(consonantTypeResults)
    .map((category) => {
      return category[1].totalCorrect;
    })
    .reduce((a, b) => a + b, 0);
  const totalIncorrect = Object.entries(consonantTypeResults)
    .map((category) => {
      return category[1].totalIncorrect;
    })
    .reduce((a, b) => a + b, 0);

  return (
    <Stack p="4">
      <Heading>Test Overview</Heading>
      <Text fontSize="xl">
        {getPercentage(totalCorrect, totalCorrect + totalIncorrect)} consonants
        correct
      </Text>
    </Stack>
  );
};

export default TestOverview;
