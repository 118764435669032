import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Logo from '../../assests/logo';

/* import { logout } from '../../redux/slices/user'; */
import { AppDispatch } from '../../redux/store';
import NavigationDrawer from './NavigationDrawer';

const NavigationBar = () => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const authenticated = useSelector((state: any) => state.auth.authenticated);

  function handleRegister() {
    history.push('/register');
  }

  function handleLogin() {
    history.push('/login');
  }

  function homepage() {
    history.push('/');
  }

  return (
    <>
      <Box
        pos={'fixed'}
        // right={0}
        width={'100%'}
        zIndex={100}
        top={0}
        bg="navbar"
        px={4}
        roundedBottom={5}
      >
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          {authenticated ? (
            <>
              <NavigationDrawer />
              <Logo />
            </>
          ) : (
            <>
              <Logo />
              <HStack as={'nav'} spacing={4}>
                <Button onClick={handleLogin} variant={'secondary'}>
                  Login
                </Button>
                <Button onClick={handleRegister} variant={'secondary'}>
                  Sign Up
                </Button>
              </HStack>
            </>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default NavigationBar;
