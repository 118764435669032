import { Text } from "@chakra-ui/react";
import React from "react";
import { ErrorPattern as IErrorPattern } from "../../models/error-pattern";
import getPercentage from "../../utils/getPercentage";

interface ErrorPatternProps {
  errorPattern: IErrorPattern;
}

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
const replaceUnderscoresWithSpaces = (str: string) => str.replaceAll("_", " ");

const ErrorPattern = ({ errorPattern }: ErrorPatternProps) => {
  const { name, occurrences, opportunities } = errorPattern;
  const formattedPatternName = replaceUnderscoresWithSpaces(capitalize(name));
  return (
    <Text fontSize="xl">
      {formattedPatternName} {occurrences} / {opportunities} (
      {getPercentage(occurrences, opportunities)})
    </Text>
  );
};

export default ErrorPattern;
