import React from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { useColorMode } from "@chakra-ui/react";

import "react-datepicker/dist/react-datepicker.css";
import "../stylesheets/date-picker.css";

function DatePicker(props: ReactDatePickerProps) {
  const { isClearable = false, ...rest } = props;
  const isLight = useColorMode().colorMode === "light";

  return (
    <div className={isLight ? "light-theme" : "dark-theme"}>
      <ReactDatePicker
        dateFormat="dd/MM/yyyy"
        isClearable={isClearable}
        className="react-datapicker__input-text"
        yearDropdownItemNumber={25}
        scrollableMonthYearDropdown={true}
        scrollableYearDropdown={true}
        showYearDropdown
        showMonthDropdown
        {...rest}
      />
    </div>
  );
}

export default DatePicker;
