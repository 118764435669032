import {
  TabList,
  TabPanels,
  TabPanel,
  Tabs,
  Tab,
  ModalBody,
} from '@chakra-ui/react';
import { on } from 'events';
import React, { FC } from 'react';
import { TranscriptionKeyboardLayoutProps } from './CombinedLayout';
import ConsonantLayout from './ConsonantLayout';
import { TranscriptionKey } from './TranscriptionKey';
import VowelLayout from './VowelLayout';

const TabbedLayout: FC<TranscriptionKeyboardLayoutProps> = ({
  onAddSound,
  onRemoveSound,
  screenWidth,
}) => {
  return (
    <Tabs isFitted variant="soft-rounded" colorScheme={'orange'}>
      <TabList ml='1em'>
        <Tab>Consonants</Tab>
        <Tab>Vowels</Tab>
        <TranscriptionKey
          key={'backspace'}
          isBackspace
          sound="⌫"
          keyWidth={screenWidth / 10}
          onSelected={onRemoveSound}
        />
      </TabList>
      <TabPanels>
        <TabPanel>
            <ConsonantLayout
              onAddSound={onAddSound}
              onRemoveSound={onRemoveSound}
              screenWidth={screenWidth}
            />
        </TabPanel>
        <TabPanel>
            <VowelLayout
              onAddSound={onAddSound}
              onRemoveSound={onRemoveSound}
              screenWidth={screenWidth}
            />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default TabbedLayout;
