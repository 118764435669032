import React from 'react';
import { useHistory } from 'react-router';
import {
  Button,
  HStack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { ResultOverview } from '../../models/result-overview';

interface ResultOverviewProps {
  result: ResultOverview;
  clientId: number;
}

const ClientResultOverview = ({ result, clientId }: ResultOverviewProps) => {
  const history = useHistory();

  return (
    <Button
      onClick={() => history.push(`/client/${clientId}/result/${result.id}`)}
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'xl'}
      rounded={'lg'}
      py="5"
      my="6"
      height={'fit'}
      width={{ base: '95%', md: 'lg' }}
    >
      <VStack fontWeight="normal">
        <Text fontSize="xl" as="b">
          {result.list_name}
        </Text>
        <Text fontSize="lg" as="i">
          {new Date(result.date).toDateString()}
        </Text>
      </VStack>
    </Button>
  );
};

export default ClientResultOverview;
