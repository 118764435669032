import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AxiosError } from 'axios';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  VStack,
  useToast,
} from '@chakra-ui/react';
import axios from '../axios-config';
import DatePicker from '../components/DatePicker';
import { useAddClientMutation } from '../redux/apiSlice';
import { AppDispatch } from '../redux/store';

interface AddClientFormValues {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
}

function AddClientPage() {
  const toast = useToast();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const authenticated = useSelector((state: any) => state.auth.authenticated);
  const [addClient, { isLoading }] = useAddClientMutation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AddClientFormValues>();

  const registerUser = async (data: AddClientFormValues) => {
    try {
      // change dateOfBirth to string as that is how the db stores it
      const formatted_dob = data.dateOfBirth.toLocaleDateString('en-NZ');
      const clientCreate = {
        ...data,
        dateOfBirth: formatted_dob,
      };
      const response = await addClient(clientCreate).unwrap();
      history.push(`/client/${response.id}`);
      toast({
        title: 'Client Added',
        status: 'success',
      });
    } catch (error) {
      const { response } = error as AxiosError;
      if (!response) {
        toast({
          title: 'Unknown error occurred',
          description: 'Please try again later',
          status: 'error',
          isClosable: true,
        });
        return;
      }

      switch (response.status) {
        case 400:
          toast({
            title: 'Invalid data',
            description: 'Please check your data',
            status: 'error',
            isClosable: true,
          });
          break;
        case 401:
          /* if (authenticated) dispatch(logout()); */
          history.push('/login');
          break;
        default:
          toast({
            title: 'Unknown error occurred',
            description: 'Please try again later',
            status: 'error',
            isClosable: true,
          });
      }
    }
  };

  return (
    <Flex pt={65} height={'94vh'} align={'center'} justify={'center'}>
      <Form onSubmit={handleSubmit(registerUser)} style={{ width: '100%' }}>
        <VStack spacing={8} py={12} px={2}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Add client</Heading>
          </Stack>
          <Box
            rounded={'lg'}
            bg="white"
            boxShadow={'lg'}
            p={8}
            width={{ base: '100%', md: '60%', lg: '40%' }}
          >
            <Stack spacing={4}>
              <FormControl
                id="firstName"
                isInvalid={errors.firstName !== undefined}
              >
                <FormLabel>First Name</FormLabel>
                <Input
                  {...register('firstName', {
                    required: 'You must enter a first name',
                    minLength: {
                      value: 2,
                      message:
                        "Client's first name must have at least 2 characters",
                    },
                  })}
                />
                <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="lastName"
                isInvalid={errors.lastName !== undefined}
              >
                <FormLabel>Surname</FormLabel>
                <Input
                  {...register('lastName', {
                    required: 'You must enter a Surname',
                    minLength: {
                      value: 2,
                      message:
                        "Client's Surname must have at least 2 characters",
                    },
                  })}
                />
                <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                {...register('dateOfBirth', {
                  required: 'You must enter a date of birth',
                })}
                id="dateOfBirth"
                isInvalid={errors.dateOfBirth !== undefined}
              >
                <FormLabel>Date of birth</FormLabel>
                <Controller
                  control={control}
                  name="dateOfBirth"
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="e.g 04/02/2016"
                      onChange={(date: Date) => field.onChange(date)}
                      selected={field.value}
                      maxDate={new Date()}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.dateOfBirth?.message}
                </FormErrorMessage>
              </FormControl>
              <Button variant="primary" type="submit">
                Add client
              </Button>
            </Stack>
          </Box>
        </VStack>
      </Form>
    </Flex>
  );
}

export default AddClientPage;
