import { extendTheme } from '@chakra-ui/react';

export const AppName = 'Speechy';

// https://maketintsandshades.com/#fbe65c,d93336
const Colors = {
  primary: {
    50: '#fedfc1',
    100: '#fdd5ac',
    200: '#fdca98',
    400: '#fdbf83',
    300: '#fcb56e',
    500: '#fcaa59',
    600: '#fba045',
    700: '#fb9530',
  },
  secondary: {
    50: '#c1dffe',
    100: '#acd5fd',
    200: '#98cafd',
    300: '#83bffd',
    400: '#6eb5fc',
    500: '#59aafc',
    600: '#45a0fb',
    700: '#3095fb',
  },
  background: '#fbfbfb',
  navbar: '#fbfbfb',
  dark: '#404040',
  error: '#3095FB',
  success: '#52aa5e',
  successHover: '#397742',
  warning: '#ffe559',
  info: '#2196f3',
  white: '#ffffff',
  black: '#000000',
  transparent: 'transparent',
  light_shade: 'rgba(13,89,166,0.1)', // Opacity is required for the scroll-shadow
  dark_shade: 'rgba(74,107,140,0.3)',
};

const Components = {
  Button: {
    variants: {
      primary: {
        bg: 'primary.500',
        color: 'white',
        rounded: 'full',
        _hover: {
          bg: 'primary.600',
        },
      },
      secondary: {
        bg: 'secondary.500',
        color: 'white',
        rounded: 'full',
        _hover: {
          bg: 'secondary.600',
        },
      },
      grey: {
        bg: 'light_shade',
        color: 'black',
        rounded: 'full',
        _hover: {
          bg: 'dark_shade',
        },
      },
      warning: {
        bg: 'red',
        color: 'white',
        rounded: 'full',
        _hover: {
          bg: 'darkRed',
        },
      },
      success: {
        bg: 'success',
        color: 'white',
        rounded: 'full',
        _hover: {
          bg: 'successHover',
        },
      },
      info: {
        bg: 'info',
        color: 'white',
        rounded: 'full',
        _hover: {
          bg: 'successHover',
        },
      },
    },
  },
  Heading: {
    variants: {
      primary: {
        color: 'black',
        rounded: 'small',
      },
      secondary: {
        color: 'dark',
        rounded: 'small',
      },
    },
  },
  Box: {
    variants: {
      scrollView: {
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'gray.200',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'gray.500',
          borderRadius: 'full',
        },
      },
    },
  },
  Card: {
    variants: {
      primary: {
        container: {
          border: 'solid orange 2px',
          boxShadow: 'xl',
          rounded: 'xl',
        },
      },
    },
  },
  Accordion: {
    variants: {
      primary: {
        root: {
          bg: 'gray.200',
          border: '1px solid gray.400',
          boxShadow: 'md',
          p: '4',
          rounded: 'md',
        },
      },
    },
  },
  Divider: {
    variants: {
      keyboard: {
        borderWidth: '3px',
        borderStyle: 'solid',
        borderColor: '#783D02',
      },
      keyboardStrong: {
        borderWidth: '10px',
        borderRadius: 'xl',
        borderStyle: 'solid',
        borderColor: '#783D02',
      },
    },
  },
  Switch: {
    variants: {
      transparent: {
        track: {
          bg: 'gray.300',
          _checked: {
            bg: 'gray.300',
          },
        },
      },
    },
  },
};

export const theme = extendTheme({ colors: Colors, components: Components });

export const toastOptions = { defaultOptions: { isClosable: true } };
