import {
  AddIcon,
  ArrowLeftIcon,
  ChevronLeftIcon,
  PlusSquareIcon,
} from '@chakra-ui/icons';
import { Button, Flex, HStack } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import AllSounds from './AllSoundsModal';
import { NewSound } from './NewSound';
import TranscriptionKeyboard from './TranscriptionKeyboard/TranscriptionKeyboard';

export interface TranscriptionSound {
  index: number;
  sound: string;
  syllableStart: boolean;
}

interface TranscriptionInputProps {
  value: string;
  onChange: (...event: any[]) => void;
}

export const TranscriptionInput: FC<TranscriptionInputProps> = ({
  value,
  onChange,
}) => {
  const [sounds, setSounds] = useState<TranscriptionSound[]>([]);

  const handleAddSound = (sound: string) => {
    const newSounds = sounds.concat({
      index: sounds.length,
      sound: sound,
      syllableStart: false,
    });
    setSounds(newSounds);
    onChange(JSON.stringify(newSounds));
  };

  const handleRemoveSound = () => {
    const newSounds = sounds.slice(0, -1);
    setSounds(newSounds);
    onChange(JSON.stringify(newSounds));
  };

  const handleChange = (sound: TranscriptionSound) => {
    const newSounds = sounds?.slice();
    newSounds.splice(sound.index, 1, sound);
    setSounds(newSounds);
    onChange(JSON.stringify(newSounds));
  };

  useEffect(() => {
    if (value) {
      setSounds(JSON.parse(value));
    }
  }, [value]);

  return (
    <>
      <HStack
        justifyContent={'space-between'}
        maxW={'90vw'}
        overflowX={'auto'}
      >
        <TranscriptionKeyboard
          onAddSound={handleAddSound}
          onRemoveSound={handleRemoveSound}
          sounds={sounds}
          toggleSyllableStart={handleChange}
        />
      </HStack>
    </>
  );
};
