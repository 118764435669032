import React from "react";
import { Heading, Stack, Text } from "@chakra-ui/react";
import Client from "../../models/client";
import getAge from "../../utils/getAge";

interface ClientContactDetailsProps {
  client: Client;
}

const ClientContactDetails = (props: ClientContactDetailsProps) => {
  const { firstName, lastName, dateOfBirth } = props.client;
  const age = getAge(dateOfBirth);

  return (
    <Stack spacing="4" align="center">
      <Heading>
        {firstName} {lastName}
      </Heading>
      <Text fontSize="xl">{age}</Text>
    </Stack>
  );
};

export default ClientContactDetails;
