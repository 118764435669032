import React, { FC, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { JsxElement } from 'typescript';

interface ConfirmDeleteAlertProps {
  resourceName: string;
  onDelete: () => void;
  loading?: boolean;
}

const ConfirmDeleteAlert: FC<ConfirmDeleteAlertProps> = ({
  resourceName,
  onDelete,
  loading,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  return (
    <>
      <Button
        isLoading={loading}
        boxShadow="lg"
        variant={'warning'}
        size={'lg'}
        onClick={onOpen}
      >
        Delete
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete {resourceName}?
            </AlertDialogHeader>

            <AlertDialogBody>
              This action will delete the {resourceName} permanently
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} variant={'grey'} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isLoading={loading}
                variant={'warning'}
                onClick={onDelete}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ConfirmDeleteAlert;
