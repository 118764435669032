import React from "react";
import { Box } from "@chakra-ui/react";

const tallyMarks = require("tally-marks");

interface CorrectProps {
  count: number;
}

const Correct = ({ count }: CorrectProps) => {
  return <Box color="success">{tallyMarks(count)}</Box>;
};

export default Correct;
