import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import AssessmentList from '../components/Lists/AssessmentList';
import {
  setBuilderList,
  setBuilderListId,
  setBuilderListName,
  setEditingBuilderListName,
} from '../redux/slices/listBuilderSlice';
import { AppDispatch } from '../redux/store';

const AddListPage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  dispatch(setBuilderList([]));
  dispatch(setBuilderListName(''));
  dispatch(setEditingBuilderListName(true));
  dispatch(setBuilderListId(undefined));

  return <AssessmentList />;
};

export default AddListPage;
