import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  AlertStatus,
  CreateStandAloneToastParam,
  UseToastOptions,
  createStandaloneToast,
} from '@chakra-ui/react';
import { speechyApi } from '../redux/apiSlice';
import { useLogoutMutation } from '../redux/apiSlice';
import { toastOptions } from '../speechy';

// Hooks used to handle errors and display toasts to users

interface ToastError {
  title: string;
  description?: string;
  status?: AlertStatus;
}

type ResourceType = 'Assessment list' | 'Assessment word';

const unknownError: ToastError = {
  title: 'Unknown error occured',
  description: 'Please try again later',
};

export const useResourceErrors = (resource: ResourceType) => {
  const { toast } = createStandaloneToast(toastOptions);
  const dispatch = useDispatch();
  const { resetApiState } = speechyApi.util;
  const [logout] = useLogoutMutation();
  const history = useHistory();

  const resourceError = (error: any) => {
    const toastErrors: Record<number, ToastError> = {
      400: {
        title: 'Invalid data',
        description: 'Please check your data',
      },
      401: {
        title: 'You are not logged in',
        description: 'Please log in and try again',
      },
      403: {
        title: `You are not the owner of this ${resource}`,
      },
      404: {
        title: `${resource} not found`,
      },
      409: {
        title: `${resource} already exists`,
        status: 'info',
      },
      422: {
        title: 'Oops something went wrong',
        description: `Couldn't procoess your ${resource}`,
      },
      500: {
        title: 'Server error',
        description: 'Please try again later',
      },
    };
    const toastError = toastErrors[error.status] || unknownError;
    toast({ ...toastError, status: toastError.status || 'error' });
    if (error.status === 401) {
      logout();
      dispatch(resetApiState());
      history.push('/');
    }
  };

  return resourceError;
};

export const useAuthErrors = () => {
  const { toast } = createStandaloneToast(toastOptions);

  const authError = (error: any) => {
    console.log(error);
    const toastErrors: Record<number, ToastError> = {
      400: {
        title: 'Invalid data',
        description: 'Please check your data',
      },
      401: {
        title: 'Incorrect email or password',
        description: 'Please try again',
      },
      409: {
        title: 'Email already exists',
        description: 'Please try again with another email',
      },
      500: {
        title: 'Server error',
        description: 'Please try again later',
      },
    };
    const toastError = toastErrors[error.status] || unknownError;
    toast({ ...toastError, status: 'error' });
  };
  return authError;
};
