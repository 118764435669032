import React from "react";
import { SoundButton } from "./SoundButton";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";

const consonants = [
  ["p", "b", "t", "d", "k", "g", "ʔ"],
  ["f", "v", "θ", "ð", "s", "z"],
  ["ɬ", "ɮ", "ʃ", "ʒ", "h"],
  ["ʧ", "ʤ"],
  ["m", "n", "ŋ"],
  ["w", "j"],
  ["l", "r"],
];
const nz_vowels = [
  ["i", "u", "ʊ"],
  ["e", "ɜ", "ɔ"],
  ["æ", "ɪ", "ə", "ɒ"],
  ["a", "ʌ"],
  ["ei", "ai", "ɔi"],
  ["oʊ", "aʊ"],
];

// eslint-disable-next-line
const us_vowels = [
  ["i", "ɪ", "ʊ", "u"],
  ["e", "ə", "ɚ", "o"], // missing char
  ["ɛ", "ʌ", "ɔ"],
  ["æ", "a", "ɑ"],
  ["ɔɪ", "aɪ", "aʊ"],
];
const vowels = nz_vowels;

interface OtherSoundsProps {
  correctSound: string;
  show: boolean;
  close: () => void;
  onSelected: (selectedCharacter: string) => void;
  selectedCharacter: string;
}

const OtherSounds = (props: OtherSoundsProps) => {
  const { correctSound, show, close, selectedCharacter } = props;

  const onSelected = (sound: string) => {
    close();
    props.onSelected(sound);
  };

  const isVowel = vowels.some((row) =>
    row.some((character) => character === correctSound)
  );

  let characters = [["ø"]];
  if (isVowel) {
    characters = characters.concat(vowels);
  } else {
    characters = characters.concat(consonants);
  }

  let clickableLetters = characters.map((row, index) => {
    const padding = isVowel && index === 4 ? "5" : "0";
    return (
      <Box key={index} className={"row justify-content-center"} paddingBottom={padding}>
        {row.map((letter, i) => {
          const isSelected = selectedCharacter === letter;
          const isCorrect = correctSound === letter;
          const isEmpty = letter === "ø";
          const colour = () => {
            if (isCorrect) {
              return "success";
            } else if (!isSelected || isEmpty) {
              return "black";
            } else {
              return "error";
            }
          };
          return (
            <SoundButton
              key={i}
              selected={isSelected}
              sound={letter}
              onSelected={onSelected}
              colour={colour()}
            />
          );
        })}
      </Box>
    );
  });

  return (
    <Modal
      onClose={close}
      isOpen={show}
      scrollBehavior="inside"
      motionPreset='scale'
      closeOnEsc={true}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent maxHeight="calc(100% - 60px)"> {/* 60px is the height of the navbar */}
        <ModalHeader>{isVowel ? "Vowels" : "Consonants"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{clickableLetters}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OtherSounds;
