import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MinusIcon } from '@chakra-ui/icons';
import { VStack } from '@chakra-ui/react';
import { Word } from '../../models/word';
import {
  removeFromBuilderList,
  setBuilderList,
} from '../../redux/slices/listBuilderSlice';
import { AppDispatch, RootState } from '../../redux/store';
import SearchFilter from '../SearchFilter';
import WordTable, { WordMenuOption } from '../Words/WordTable';
import ListBuilderButtons from './ListBuilderButtons';
import ListBuilderName from './ListBuilderName';

const CustomListTab: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [filter, setFilter] = useState('');
  const list = useSelector((state: RootState) => state.listBuilder.list);

  const removeWordMenuOption: WordMenuOption = {
    label: 'Remove from list',
    onSelect: (word: Word) => dispatch(removeFromBuilderList(word)),
    icon: <MinusIcon />,
    variant: 'warning',
  };

  const menuOptions = [removeWordMenuOption];

  return (
    <div>
      <VStack py={{ base: '150px', md: '160px' }}>
        {/* New/Editing list word table */}
        <WordTable
          words={list}
          filter={filter}
          loading={false}
          setWords={(words) => dispatch(setBuilderList(words))}
          droppableId="editingList"
          menuOptions={menuOptions}
          editable={filter.length === 0}
          height={'calc(100vh - 250px)'}
        />
        <VStack
          bg="background"
          w="100%"
          alignItems={'center'}
          pos="fixed"
          top={'130px'}
          boxShadow="md"
          py="10px"
        >
          {/* List name  */}
          <ListBuilderName />
          <SearchFilter filter={filter} setFilter={setFilter} />
        </VStack>
        {/* Save and cancel buttons */}
        <ListBuilderButtons />
      </VStack>
    </div>
  );
};

export default CustomListTab;
