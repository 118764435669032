import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useLoginMutation } from '../redux/apiSlice';
import { LoginRequest } from '../redux/slices/auth';
import { useAuthErrors } from '../utils/errorHooks';
import { emailRegex } from '../validation/regex';

const LoginPage = () => {
  const history = useHistory();
  const toast = useToast();
  const authError = useAuthErrors();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRequest>({ criteriaMode: 'all' });
  const [login] = useLoginMutation();

  const loginRTKQuery = async (data: LoginRequest) => {
    try {
      const authResponse = await login(data).unwrap();
      history.push('/');
      toast({
        title: authResponse.message,
        status: 'success',
      });
    } catch (error: any) {
      console.log(error);
      authError(error);
    }
  };

  return (
    <Flex pt={65} h="100%" align="center" justify="center">
      <form onSubmit={handleSubmit(loginRTKQuery)}>
        <Stack spacing={8} minW={'30vw'} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Login</Heading>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}
          >
            <Stack spacing={4}>
              <FormControl id="email" isInvalid={errors.email !== undefined}>
                <FormLabel>Email Address</FormLabel>
                <Input
                  {...register('email', {
                    required: 'You must enter an email',
                    pattern: {
                      value: emailRegex,
                      message: 'This email is not valid',
                    },
                  })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="password"
                isInvalid={errors.password !== undefined}
              >
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  {...register('password', {
                    required: 'A password is required',
                  })}
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>

              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Flex>
  );
};

export default LoginPage;
