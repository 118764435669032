import { SerializedError, createSlice } from '@reduxjs/toolkit';
import { speechyApi } from '../apiSlice';

export interface UserState {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  clinicId?: number | null;
  authenticated: boolean;
  error?: SerializedError;
}

const initialState: UserState = {
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  clinicId: undefined,
  authenticated: false,
  error: undefined,
};

export interface LoginRequest {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface AuthResponse {
  message: string;
  user: {
    id: number;
    clinicId: number;
    firstName: string;
    lastName: string;
    email: string;
  };
}
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      speechyApi.endpoints.signUp.matchFulfilled,
      (state, { payload }) => {
        const { user } = payload as AuthResponse;
        state.id = user.id;
        state.clinicId = user.clinicId;
        state.email = user.email;
        state.firstName = user.firstName;
        state.lastName = user.lastName;
        state.authenticated = true;
      },
    );
    builder.addMatcher(speechyApi.endpoints.signUp.matchRejected, (state) => {
      state.id =
        state.firstName =
        state.lastName =
        state.email =
        state.clinicId =
          undefined;
      state.authenticated = false;
    });
    builder.addMatcher(
      speechyApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        const { user } = payload as AuthResponse;
        state.id = user.id;
        state.clinicId = user.clinicId;
        state.email = user.email;
        state.firstName = user.firstName;
        state.lastName = user.lastName;
        state.authenticated = true;
      },
    );
    builder.addMatcher(speechyApi.endpoints.login.matchRejected, (state) => {
      state.id =
        state.firstName =
        state.lastName =
        state.email =
        state.clinicId =
          undefined;
      state.authenticated = false;
    });
    builder.addMatcher(speechyApi.endpoints.logout.matchFulfilled, (state) => {
      state.id =
        state.firstName =
        state.lastName =
        state.email =
        state.clinicId =
          undefined;
      state.authenticated = false;
    });
    builder.addMatcher(speechyApi.endpoints.logout.matchRejected, (state) => {
      state.id =
        state.firstName =
        state.lastName =
        state.email =
        state.clinicId =
          undefined;
      state.authenticated = false;
    });
  },
});
