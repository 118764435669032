import React, { useState } from 'react';
import {
  Box,
  Center,
  HStack,
  Heading,
  Show,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  PVMConsonantTypes,
  PlaceVoiceManner,
} from '../../models/place-voice-manner';
import '../../stylesheets/scroll-shadow.css';
import HozTable from './HozTable';
import VerTable from './VerTable';

interface PVMProps {
  placeVoiceManner: PlaceVoiceManner;
  consonantTypes: PVMConsonantTypes;
  consonantTypeResults: {
    [category: string]: { totalCorrect: number; totalIncorrect: number };
  };
  openCategories: Set<number>;
}

function PVM(props: PVMProps) {
  let {
    placeVoiceManner,
    consonantTypeResults,
    consonantTypes,
    openCategories,
  } = props;
  const [showVertical, setShowVertical] = useState(true);

  return (
    <Box pb={10}>
      <Heading p="4">Place Voice Manner Analysis</Heading>
      <Center mt="4">
        <VStack width="100%">
          <Show above="lg">
            <HStack spacing={'3'}>
              {/* Fixes weird alignment issue */}
              <Text />
              <Text>Vertical</Text>
              <Switch
                variant="transparent"
                onChange={() => setShowVertical(!showVertical)}
              />
              <Text>Horizontal</Text>
            </HStack>
          </Show>
          {showVertical ? (
            <Box width={{ base: '100%', md: '60%', lg: '40%' }} bg="white">
              <VerTable
                placeVoiceManner={placeVoiceManner}
                openCategories={openCategories}
                consonantTypeResults={consonantTypeResults}
                consonantTypes={consonantTypes}
              />
            </Box>
          ) : (
            <Box
              width={{ base: '100%', sm: 'auto' }}
              maxW={{ sm: '95%' }}
              bg="white"
            >
              <HozTable
                placeVoiceManner={placeVoiceManner}
                consonantTypeResults={consonantTypeResults}
                consonantTypes={consonantTypes}
              />
            </Box>
          )}
        </VStack>
      </Center>
    </Box>
  );
}

export default PVM;
