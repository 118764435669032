import { VStack, HStack, Box, Divider, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { TranscriptionKeyboardLayoutProps } from './CombinedLayout';
import { TranscriptionKey } from './TranscriptionKey';

const ConsonantLayout: FC<TranscriptionKeyboardLayoutProps> = ({
    onAddSound,
    onRemoveSound,
    screenWidth,
}) => {
    const keyWidth = Math.round(screenWidth / 11);

    const consonants = {
        nasals: ['m', 'n', 'ŋ'],
        stops: ['p', 'b', 't', 'd', 'k', 'g', 'ʔ'],
        fricatives: ['θ', 'ð', 'f', 'v', 's', 'z', 'ʃ', 'ʒ', 'h'],
        affricates: ['ʧ', 'ʤ'],
        liquids: ['l', 'r', 'ɚ'],
        glides: ['w', 'j'],
    };
    return (
        <Flex>
            <VStack flex={9} pb={5} h={290}>
                <Box>
                    {consonants.stops.map((v) => (
                        <TranscriptionKey
                            key={v}
                            keyWidth={keyWidth}
                            sound={v}
                            onSelected={() => onAddSound(v)}
                            color={'#F3861A'}
                        />
                    ))}
                </Box>
                <Box>
                    {consonants.fricatives.slice(0, 4).map((v) => (
                        <TranscriptionKey
                            key={v}
                            keyWidth={keyWidth}
                            sound={v}
                            onSelected={() => onAddSound(v)}
                            color={'#F3861A'}
                        />
                    ))}
                </Box>
                <Box>
                    {consonants.fricatives.slice(4, 9).map((v) => (
                        <TranscriptionKey
                            key={v}
                            keyWidth={keyWidth}
                            sound={v}
                            onSelected={() => onAddSound(v)}
                            color={'#F3861A'}
                        />
                    ))}
                </Box>
                <Box>
                    {consonants.nasals.concat(consonants.affricates).map((v) => (
                        <TranscriptionKey
                            key={v}
                            keyWidth={keyWidth}
                            sound={v}
                            onSelected={() => onAddSound(v)}
                            color={'#F3861A'}
                        />
                    ))}
                </Box>
                <Box>
                    {consonants.glides.concat(consonants.liquids).map((v) => (
                        <TranscriptionKey
                            key={v}
                            keyWidth={keyWidth}
                            sound={v}
                            onSelected={() => onAddSound(v)}
                            color={'#F3861A'}
                        />
                    ))}
                </Box>
                {/* <HStack height={{ base: '40px', lg: '66px' }}>
                    <Box>
                        {consonants.nasals.map((v) => (
                            <TranscriptionKey
                                keyWidth={keyWidth}
                                sound={v}
                                onSelected={() => onAddSound(v)}
                                color={'#F3861A'}
                            />
                        ))}
                    </Box>
                    <Divider orientation="vertical" variant={'keyboard'} />
                    <Box>
                        {consonants.affricates.map((v) => (
                            <TranscriptionKey
                                keyWidth={keyWidth}
                                sound={v}
                                onSelected={() => onAddSound(v)}
                                color={'#F3861A'}
                            />
                        ))}
                    </Box>
                </HStack>
                <HStack height={{ base: '40px', lg: '66px' }}>
                    <Box>
                        {consonants.glides.map((v) => (
                            <TranscriptionKey
                                keyWidth={keyWidth}
                                sound={v}
                                onSelected={() => onAddSound(v)}
                                color={'#F3861A'}
                            />
                        ))}
                    </Box>
                    <Divider orientation="vertical" variant={'keyboard'} />
                    <Box>
                        {consonants.liquids.map((v) => (
                            <TranscriptionKey
                                keyWidth={keyWidth}
                                sound={v}
                                onSelected={() => onAddSound(v)}
                                color={'#F3861A'}
                            />
                        ))}
                    </Box>
                </HStack> */}
            </VStack>
        </Flex>
    );
};

export default ConsonantLayout;
