import React, { FC, useEffect, useState } from 'react';
import { Box, Heading, VStack } from '@chakra-ui/react';
import SearchFilter from '../components/SearchFilter';
import AddWordModal from '../components/Words/AddWordModal';
import WordTable from '../components/Words/WordTable';
import { Word } from '../models/word';
import { useFetchWordsQuery } from '../redux/apiSlice';

const WordsPage: FC = () => {
  const [words, setWords] = useState<Word[]>([]);
  const [filter, setFilter] = useState('');
  const { data, isLoading } = useFetchWordsQuery();

  useEffect(() => {
    if (data) setWords(data);
  }, [data]);

  return (
    <VStack
      alignItems="center"
      pt={{ base: '165px', md: '175px' }}
      px={{ base: '0', md: '100px', lg: '200px', xl: '300px' }}
      pb={90}
    >
      {/* Assessment word table */}

      <WordTable
        words={words}
        filter={filter}
        setWords={setWords}
        loading={isLoading}
        droppableId="lexemes"
      />
      {/* Heading and search bar */}
      <VStack
        bg="background"
        w="100%"
        alignItems={'center'}
        pos="fixed"
        top={'50px'}
        boxShadow="md"
        py="10px"
      >
        <Heading size={{ base: 'md', md: 'lg' }} variant={'primary'}>
          Assessment Words
        </Heading>
        <SearchFilter filter={filter} setFilter={setFilter} />
      </VStack>
      {/* Add Word button */}
      <Box
        pos="fixed"
        bottom="32px"
        right={{ base: '32px', lg: '10vw', xl: '20vw' }}
      >
        <AddWordModal />
      </Box>
    </VStack>
  );
};

export default WordsPage;
