import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Button, Heading, Stack } from '@chakra-ui/react';
import ListsAccordian from '../components/Lists/ListsAccordian';

function ListsPage() {
  const history = useHistory();
  return (
    <Stack pt={65} pb={90} alignItems="center">
      <Heading variant={'primary'}>Assessment Lists</Heading>
      <ListsAccordian />

      <Button
        onClick={() => history.push('/addlist')}
        variant="primary"
        h="3rem"
        size="lg"
        aria-label="Add List"
        alignSelf="flex-end"
        style={{ position: 'fixed', bottom: '32px', right: '32px' }}
        leftIcon={<FaPlus />}
        boxShadow="lg"
      >
        Add List
      </Button>
    </Stack>
  );
}

export default ListsPage;
