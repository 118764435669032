import React from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
} from "@chakra-ui/react";
import Jumbotron from "react-bootstrap/Jumbotron";

const MailingList = () => {
  return (
    <Jumbotron style={{marginBottom: 0}}>
      <Container maxW={"5xl"}>
        <Box>
          <form
            action="https://canterbury.us13.list-manage.com/subscribe/post?u=c2027b16b620fc2e138c04eda&id=b97dd21fc2&f_id=0040e3e2f0"
            method="post"
            name="mc-embedded-subscribe-form"
            target="_blank"
            noValidate
          >
            <VStack spacing={3}>
              <Heading>Subscribe for updates!</Heading>
              <FormControl isRequired>
                <FormLabel>Email Address</FormLabel>
                <Input
                  type="email"
                  name="EMAIL"
                  required
                  variant={"filled"}
                  bg={"white"}
                />
              </FormControl>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  name="FNAME"
                  variant={"filled"}
                  bg={"white"}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  name="LNAME"
                  variant={"filled"}
                  bg={"white"}
                />
              </FormControl>
              {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <Input
                  type="text"
                  name="b_c2027b16b620fc2e138c04eda_b97dd21fc2"
                  tabIndex={-1}
                  defaultValue=""
                />
              </div>
              <Button
                type="submit"
                name="subscribe"
                variant={"primary"}
              >
                Submit
              </Button>
            </VStack>
          </form>
        </Box>
      </Container>
    </Jumbotron>
  );
};

export default MailingList;
