import React, { FC, useEffect, useState } from 'react';
import { Accordion } from '@chakra-ui/accordion';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Spacer,
  Spinner,
} from '@chakra-ui/react';
import { List, ListSummary } from '../../models/list';
import { useFetchListSummariesQuery } from '../../redux/apiSlice';
import ListWordTable from '../Words/ListWordTable';
import ListsAccordianItem from './ListsAccordianItem';

export interface ListMenuOption {
  label: string;
  onSelect: (list: List) => void;
  icon: JSX.Element;
  variant?: string;
}

const ListsAccordian: FC = () => {
  const [lists, setLists] = useState<ListSummary[]>([]);
  const { data, error, isLoading } = useFetchListSummariesQuery();

  useEffect(() => {
    if (data) setLists(data);
  }, [data]);

  return (
    <Box width={{ base: '100%', md: '60%', lg: '40%' }} bg="white">
      <Accordion allowToggle>
        {isLoading ? (
          <Spinner />
        ) : (
          lists.map((l) => (
            <AccordionItem key={l.list_id}>
              {({ isExpanded }) => (
                <div>
                  <h2>
                    <AccordionButton
                      bg={'gray.200'}
                      _expanded={{ bg: 'dark_shade' }}
                    >
                      <Box flex="1" textAlign="left" textTransform="capitalize">
                        {l.name}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel p={0}>
                    {isExpanded && <ListWordTable list_id={l.list_id} />}
                  </AccordionPanel>
                </div>
              )}
            </AccordionItem>
          ))
        )}
      </Accordion>
      <Box bg="dark_shade" pl="4" py="3" pr="9">
        <Flex flex="1">
          <Box textAlign="left" textTransform="capitalize">
            TOTAL LISTS
          </Box>
          <Spacer minW="10px" />
          <Box textAlign="right">{lists.length}</Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default ListsAccordian;
