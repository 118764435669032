import React from "react";
import { PlaceVoiceManner, PVMCategory } from "../../models/place-voice-manner";
import ConsonantPlace from "./ConsonantPlace";
import { Td, Tr } from "@chakra-ui/react";

interface ConsonantProps {
  categoryName: PVMCategory;
  sounds: string[];
  placeVoiceManner: PlaceVoiceManner;
}

export const voiced = [
  "m",
  "n",
  "ŋ",
  "b",
  "d",
  "g",
  "ð",
  "v",
  "z",
  "ʒ",
  "ʤ",
  "l",
  "r",
  "w",
  "j",
  "ɮ",
];

const Consonant = ({
  categoryName,
  sounds,
  placeVoiceManner,
}: ConsonantProps) => {
  return (
    <>
      {sounds.map((sound, index) => {
        const isVoiced = voiced.includes(sound);
        const results = placeVoiceManner[categoryName];

        return (
          <Tr key={categoryName + index} bg={isVoiced ? "light_shade" : ""}>
            <Td>{sound}</Td>
            {Object.keys(results).includes(sound) ? (
              <>
                <Td>
                  <ConsonantPlace
                    consonant={results[sound].initial_consonant}
                  />
                </Td>
                <Td>
                  <ConsonantPlace consonant={results[sound].medial_consonant} />
                </Td>
                <Td>
                  <ConsonantPlace consonant={results[sound].final_consonant} />
                </Td>
              </>
            ) : (
              <>
                <Td />
                <Td />
                <Td />
              </>
            )}
          </Tr>
        );
      })}
    </>
  );
};

export default Consonant;
