import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useSignUpMutation } from '../redux/apiSlice';
import { SignUpRequest } from '../redux/slices/auth';
import { useAuthErrors } from '../utils/errorHooks';
import { emailRegex } from '../validation/regex';

interface RegisterFormValues extends SignUpRequest {
  confirmPassword: string;
}

function RegisterPage() {
  const toast = useToast();
  const history = useHistory();
  const authError = useAuthErrors();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<RegisterFormValues>({ criteriaMode: 'all' });
  const [signUp, { isLoading }] = useSignUpMutation();

  const registerRTKQuery = async (data: RegisterFormValues) => {
    const { confirmPassword, ...signUpData } = data;
    try {
      const authResponse = await signUp(signUpData).unwrap();
      history.push('/');
      toast({
        title: authResponse.message,
        status: 'success',
      });
    } catch (error: any) {
      authError(error);
    }
  };

  return (
    <Flex pt={65} h="100%" align="center" justify="center">
      <form onSubmit={handleSubmit(registerRTKQuery)}>
        <Stack spacing={8} minW={'30vw'} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Sign up</Heading>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}
          >
            <Stack spacing={4}>
              <FormControl
                id="firstName"
                isInvalid={errors.firstName !== undefined}
              >
                <FormLabel>First Name</FormLabel>
                <Input
                  {...register('firstName', {
                    required: 'You must enter a First name',
                    minLength: {
                      value: 2,
                      message:
                        'Your first name must have at least 2 characters',
                    },
                  })}
                />
                <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="lastName"
                isInvalid={errors.lastName !== undefined}
              >
                <FormLabel>Surname</FormLabel>
                <Input
                  {...register('lastName', {
                    required: 'You must enter a Surname',
                    minLength: {
                      value: 2,
                      message: 'Your Surname must have at least 2 characters',
                    },
                  })}
                />
                <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="email" isInvalid={errors.email !== undefined}>
                <FormLabel>Email Address</FormLabel>
                <Input
                  {...register('email', {
                    required: 'You must enter an email',
                    pattern: {
                      value: emailRegex,
                      message: 'This email is not valid',
                    },
                  })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="password"
                isInvalid={errors.password !== undefined}
              >
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  {...register('password', {
                    required: 'A password is required',
                    validate: {
                      uppercase: (value) => RegExp('(.*[A-Z].*)').test(value),
                      number: (value) => RegExp('(.*\\d.*)').test(value),
                      special: (value) =>
                        RegExp('[!@#$%^&*(),.?":{}|<>]').test(value),
                      length: (value) =>
                        6 <= value.length && value.length <= 16,
                    },
                  })}
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                <Collapse in={errors.password !== undefined}>
                  <Stack spacing={0} direction={'column'}>
                    <div />
                    <Checkbox
                      isChecked={!errors.password?.types?.uppercase}
                      isReadOnly
                    >
                      Uppercase
                    </Checkbox>
                    <Checkbox
                      isChecked={!errors.password?.types?.number}
                      isReadOnly
                    >
                      Number
                    </Checkbox>
                    <Checkbox
                      isChecked={!errors.password?.types?.special}
                      isReadOnly
                    >
                      Special Character
                    </Checkbox>
                    <Checkbox
                      isChecked={!errors.password?.types?.length}
                      isReadOnly
                    >
                      Between 6-16 Characters
                    </Checkbox>
                  </Stack>
                </Collapse>
              </FormControl>
              <FormControl
                id="confirmPassword"
                isInvalid={errors.confirmPassword !== undefined}
              >
                <FormLabel>Confirm Password</FormLabel>
                <Input
                  type="password"
                  {...register('confirmPassword', {
                    validate: (value) =>
                      value === getValues().password ||
                      'Passwords should match',
                  })}
                />
                <FormErrorMessage>
                  {errors.confirmPassword?.message}
                </FormErrorMessage>
              </FormControl>

              <Button variant="primary" type="submit">
                Sign up
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Flex>
  );
}

export default RegisterPage;
