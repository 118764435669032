import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom';
import { Box, ChakraProvider } from '@chakra-ui/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBar from './components/Navigation/NavigationBar';
import AddClientPage from './pages/AddClientPage';
import AddListPage from './pages/AddListPage';
import AnalysisPage from './pages/AnalysisPage';
import AnalysisPageTS from './pages/AnalysisPageTS';
import ClientPage from './pages/ClientPage';
import ClientsPage from './pages/ClientsPage';
import EditListPage from './pages/EditListPage';
import EditResultsPage from './pages/EditResultsPage';
import LandingPage from './pages/LandingPage';
import ListsPage from './pages/ListsPage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import RegisterPage from './pages/RegisterPage';
import ResultPage from './pages/ResultPage';
import UnderMaintenancePage from './pages/UnderMaintenancePage';
import WordsPage from './pages/WordsPage';
import { theme, toastOptions } from './speechy';

let underMaintenance = false;
if (process.env.REACT_APP_UNDER_MAINTENANCE === 'true') {
  underMaintenance = true;
}

function App() {
  const authenticated = useSelector((state: any) => state.auth.authenticated);
  const userID = useSelector((state: any) => state.auth.id);

  class ProtectedRoute extends Route<RouteProps> {
    public render() {
      if (!authenticated) {
        let redirectPath: string = '/login';
        const renderComponent = () => (
          <Redirect to={{ pathname: redirectPath }} />
        );
        return (
          <Route
            {...this.props}
            component={renderComponent}
            render={undefined}
          />
        );
      } else {
        return <Route {...this.props} />;
      }
    }
  }

  return (
    <BrowserRouter>
      <ChakraProvider toastOptions={toastOptions} theme={theme}>
        <Box minH="100vh" w="100%" bg={theme.colors.background}>
          {underMaintenance ? (
            <UnderMaintenancePage />
          ) : (
            <>
              <NavigationBar />
              <Switch>
                {!authenticated && (
                  <Route exact path="/" component={LandingPage} />
                )}
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/register" component={RegisterPage} />
                {/* <ProtectedRoute exact path="/" component={HomePage} /> */}
                <ProtectedRoute exact path="/" component={ClientsPage} />
                <ProtectedRoute
                  exact
                  path="/client/:id"
                  component={ClientPage}
                />
                <ProtectedRoute
                  exact
                  path="/client/:clientId/result/:resultId"
                  component={ResultPage}
                />
                <ProtectedRoute exact path="/clients" component={ClientsPage} />
                <ProtectedRoute exact path="/words" component={WordsPage} />
                <ProtectedRoute exact path="/lists" component={ListsPage} />
                <ProtectedRoute
                  exact
                  path="/lists/:listId"
                  component={EditListPage}
                />
                <ProtectedRoute
                  exact
                  path="/addclient"
                  component={AddClientPage}
                />
                <ProtectedRoute exact path="/addlist" component={AddListPage} />
                <ProtectedRoute
                  exact
                  path="/analysis/:clientId/list/:listId"
                  component={AnalysisPageTS}
                />
                <ProtectedRoute
                  exact
                  path="/analysis/:clientId/result/:resultId"
                  component={EditResultsPage}
                />
                <Route exact path="/404" component={NotFoundPage} />

                <Redirect to="/404" />
              </Switch>
            </>
          )}
        </Box>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
