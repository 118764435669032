import React, { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Button, Heading, SimpleGrid, Skeleton, Stack } from '@chakra-ui/react';
import camelCase from 'camelcase-keys';
import ClientSummary from '../components/Client/ClientSummary';
import Client from '../models/client';
import { useFetchClientsQuery } from '../redux/apiSlice';

function ClientsPage() {
  const history = useHistory();
  const [clients, setClients] = useState<Client[]>([]);
  const { data, isLoading } = useFetchClientsQuery();

  useEffect(() => {
    if (data) setClients(camelCase(data));
  }, [data]);

  const clientsDisplay =
    clients.length === 0 && !isLoading ? (
      <Heading textAlign="center">No clients</Heading>
    ) : (
      <SimpleGrid
        w={{ base: '100%', sm: 'md', lg: '2xl', xl: '4xl' }}
        columns={{ sm: 1, md: 2, lg: 3 }}
        spacing={'10'}
      >
        {isLoading
          ? [...Array(6)].map((e, i) => (
              <Skeleton
                key={i}
                height={{
                  base: '110vw',
                  sm: '537px',
                  md: '293px',
                  lg: '287px',
                  xl: '361px',
                }}
              />
            ))
          : clients.map((client) => (
              <div style={{ cursor: 'pointer' }} key={client.id}>
                <ClientSummary client={client} />
              </div>
            ))}
      </SimpleGrid>
    );

  return (
    <Stack marginTop={'50px'} p="8" alignItems="center">
      <Heading variant={'primary'}>Clients</Heading>
      {clientsDisplay}
      <Button
        onClick={() => history.push('/addclient')}
        variant="primary"
        h="3rem"
        size="lg"
        aria-label="Add Client"
        alignSelf="flex-end"
        style={{ position: 'fixed', bottom: '32px' }}
        leftIcon={<FaPlus />}
      >
        Add Client
      </Button>
    </Stack>
  );
}

export default ClientsPage;
