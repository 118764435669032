import React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, HStack, useToast } from '@chakra-ui/react';
import {
  useAddListMutation,
  useDeleteListMutation,
  useUpdateListMutation,
} from '../../redux/apiSlice';
import { RootState } from '../../redux/store';
import { useResourceErrors } from '../../utils/errorHooks';
import ConfirmDeleteAlert from '../ConfirmDeleteAlert';

const ListBuilderButtons: FC = () => {
  const history = useHistory();
  const resourceError = useResourceErrors('Assessment list');
  const list = useSelector((state: RootState) => state.listBuilder.list);
  const listName = useSelector((state: RootState) => state.listBuilder.name);
  const listId = useSelector((state: RootState) => state.listBuilder.list_id);
  const [updateList, { isLoading: updateIsLoading }] = useUpdateListMutation();
  const [addList, { isLoading: addIsLoading }] = useAddListMutation();
  const [removeList, { isLoading: deleteIsLoading }] = useDeleteListMutation();
  const toast = useToast();

  const handleDeleteList = async () => {
    try {
      await removeList(listId!);
      history.push('/lists');
    } catch (error: any) {
      resourceError(error);
    }
  };

  const handleUpdateList = async () => {
    if (!listName) {
      toast({
        title: 'Please name your assessment list',
        status: 'warning',
        isClosable: true,
      });
    } else {
      try {
        await updateList({
          list_id: listId!,
          name: listName!,
          lexemes: list,
        }).unwrap();
        toast({
          title: `${listName} updated`,
          status: 'success',
          isClosable: true,
        });
        history.push('/lists');
      } catch (error: any) {
        resourceError(error);
      }
    }
  };

  const handleAddList = async () => {
    if (!listName) {
      toast({
        title: 'Please name your assessment list',
        status: 'warning',
        isClosable: true,
      });
    } else {
      try {
        await addList({ name: listName!, lexemes: list }).unwrap();
        toast({
          title: `${listName} created`,
          status: 'success',
          isClosable: true,
        });
        history.goBack();
      } catch (error: any) {
        resourceError(error);
      }
    }
  };

  return (
    <HStack justifyContent={'flex-end'} pos="fixed" bottom="32px">
      <Button
        onClick={() => {
          history.push('/lists');
        }}
        h="3rem"
        rounded={'full'}
        size="lg"
        boxShadow="lg"
        aria-label="Cancel"
      >
        Cancel
      </Button>
      {listId && (
        <ConfirmDeleteAlert
          resourceName="Assessment List"
          onDelete={handleDeleteList}
          loading={deleteIsLoading}
        />
      )}
      <Button
        onClick={listId ? handleUpdateList : handleAddList}
        isLoading={updateIsLoading || addIsLoading}
        loadingText="Saving"
        variant="info"
        boxShadow="lg"
        h="3rem"
        size="lg"
        aria-label="Add Assessment List"
      >
        {listId ? 'Save' : 'Create'}
      </Button>
    </HStack>
  );
};

export default ListBuilderButtons;
