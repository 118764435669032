export default function getTranscription(transcription: String[], syllable_position: number[]) {
    const transcriptionArray = []
    let prev_syl = 0
    for (let sylPos of syllable_position) {
        transcriptionArray.push(transcription.slice(prev_syl, sylPos + 1).join(""))
        prev_syl = sylPos + 1
    }
    transcriptionArray.push(transcription.slice(prev_syl).join(""))
  return '/ ' + transcriptionArray.join(" . ") + ' /'
}
