import * as React from 'react';
import { useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { Word } from './Word';

interface WordDropdownProps {
  words: any[];
  wordIndex: number;
  onChange: (value: string | string[]) => void;
}

export const WordDropdown = (props: WordDropdownProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { words, wordIndex, onChange } = props;
  const word = words[wordIndex];

  return (
    <Menu
      onOpen={() => setDrawerOpen(true)}
      onClose={() => setDrawerOpen(false)}
      matchWidth
    >
      <MenuButton
        as={Word}
        isOpen={drawerOpen}
        borderRadius="25px"
        _hover={{ bg: 'gray.100' }}
        fontSize={{ base: '4xl', sm: '5xl', md: '6xl' }}
      >
        {word.word.charAt(0).toUpperCase() + word.word.slice(1)}
      </MenuButton>
      <MenuList style={{ maxHeight: '50vh', overflow: 'auto' }}>
        <MenuOptionGroup
          defaultValue={wordIndex.toString()}
          type="radio"
          onChange={onChange}
        >
          {Object.entries(words).map(([id, _word]) => {
            const correct =
              JSON.stringify(_word.selectedSounds) ===
              JSON.stringify(_word.correctSounds);
            let colour = correct ? 'success' : 'error';
            colour = _word.beenTested ? colour : 'white';
            return (
              <MenuItemOption bg={colour} key={id} value={id.toString()}>
                {_word.word.charAt(0).toUpperCase() + _word.word.slice(1)}
              </MenuItemOption>
            );
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
