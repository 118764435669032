import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  ThemingProps,
  useStyleConfig,
} from "@chakra-ui/system";
import { __DEV__, cx } from "@chakra-ui/utils";
import * as React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export interface WordProps
  extends HTMLChakraProps<"h1">,
    ThemingProps<"Heading"> {
  isOpen?: boolean;
}

export const Word = forwardRef<WordProps, "h1">((props, ref) => {
  const styles = useStyleConfig("Heading", props);
  styles.userSelect = "none";
  styles.cursor = "pointer";
  styles.textAlign = "center";
  const { className, isOpen, ...rest } = omitThemingProps(props);

  const arrowStyles = {
    transform: isOpen ? "rotate(180deg)" : "",
    transition: "transform 150ms ease", // smooth transition
  };

  return (
    <chakra.h1
      ref={ref}
      className={cx("chakra-heading", props.className)}
      {...rest}
      __css={styles}
    >
      {props.children}
      <ChevronDownIcon style={arrowStyles} />
    </chakra.h1>
  );
});

if (__DEV__) {
  Word.displayName = "WordDropdown";
}
