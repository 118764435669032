import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
  useToast,
} from '@chakra-ui/react';
import apple_transcription_example from '../../assests/images/apple_transcription_example.png';
import {
  APIWordCreate,
  APIWordCreateResponse,
  ListWord,
  Transcription,
  TranscriptionSound,
  Word,
} from '../../models/word';
import {
  useAddAssessmentWordMutation,
  useFetchWordsQuery,
} from '../../redux/apiSlice';
import { addToBuilderList } from '../../redux/slices/listBuilderSlice';
import { AppDispatch } from '../../redux/store';
import { useResourceErrors } from '../../utils/errorHooks';
import InfoPopover from '../InfoPopover';
import { TranscriptionInput } from './TranscriptionInput';

export interface WordFormValues {
  spelling: string;
  transcription: string;
}

interface WordFormProps {
  onClose: () => void;
  spellingInput?: string;
}

const WordForm: FC<WordFormProps> = ({ onClose, spellingInput }) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const history = useHistory();
  const resourceError = useResourceErrors('Assessment word');
  const { data: assessmentWords } = useFetchWordsQuery();
  const [addWord, { isLoading }] = useAddAssessmentWordMutation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<WordFormValues>({
    defaultValues: {
      spelling: spellingInput ?? '',
      transcription: '[]',
    },
  });

  // check path to see if word should be added to builder list
  const isEditingList =
    /^\/lists\/(\d+)$/.test(history.location.pathname) ||
    /^\/addlist/.test(history.location.pathname);

  const formatWordForApi = (data: WordFormValues) => {
    const sounds = JSON.parse(data.transcription);
    const transcription = sounds.map((s: TranscriptionSound) => s.sound);
    const syllablePosition = sounds
      .map((s: TranscriptionSound, i: number) => (s.syllableStart ? i : null))
      .filter((i: number) => i !== null);
    const apiWordCreate = {
      spelling: data.spelling,
      transcription: transcription,
      syllable_position: syllablePosition,
    } as APIWordCreate;
    return apiWordCreate;
  };

  const submitForm = async (data: WordFormValues) => {
    try {
      const wordCreate = formatWordForApi(data);
      const response = await addWord(wordCreate).unwrap();
      if (isEditingList) {
        const newTranscription = createNewTranscription(wordCreate, response);
        const wordToAdd = getWordToAdd(wordCreate, response, newTranscription);
        dispatch(addToBuilderList(wordToAdd));
      }
      toast({
        title: 'Assesment word added',
        status: 'success',
      });
      onClose();
    } catch (error: any) {
      resourceError(error);
      if (error.status === 409) {
        if (isEditingList) {
          const wordToAdd = getExistingWordToAdd(error.data);
          dispatch(addToBuilderList(wordToAdd));
        }
      }
      onClose();
    }
  };

  const findWord = (response: APIWordCreateResponse): undefined | Word => {
    return assessmentWords?.find((w) => w.word_id === response.word_id);
  };

  const createNewTranscription = (
    wordCreate: APIWordCreate,
    response: APIWordCreateResponse,
  ): Transcription => {
    const transcription = {
      transcription: wordCreate.transcription,
      transcription_id: response.transcription_id,
      syllable_position: wordCreate.syllable_position,
      list_count: 0,
    } as Transcription;
    return transcription;
  };

  const getWordToAdd = (
    wordCreate: APIWordCreate,
    response: APIWordCreateResponse,
    newTranscription: Transcription,
  ): Word => {
    // Gets the word to add to the list builder if it is a brand
    // new word or if it already exists
    const word = findWord(response);
    let wordToAdd: Word;
    if (word) {
      // copy word
      wordToAdd = JSON.parse(JSON.stringify(word));
      wordToAdd.transcriptions.unshift(newTranscription);
      wordToAdd.selected_transcription = 0;
    } else {
      wordToAdd = {
        word: wordCreate.spelling,
        word_id: response.word_id,
        selected_transcription: 0,
        transcriptions: [newTranscription],
      };
    }
    return wordToAdd;
  };

  const getExistingWordToAdd = (response: APIWordCreateResponse): Word => {
    // Finds the existing word and sets the correct transcription
    const word = findWord(response);
    const wordToAdd = JSON.parse(JSON.stringify(word));
    wordToAdd.selected_transcription = wordToAdd.transcriptions.findIndex(
      (t: Transcription) => t.transcription_id === response.transcription_id,
    );
    return wordToAdd;
  };

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <VStack p={8}>
        <FormControl id="spelling" isInvalid={errors.spelling !== undefined}>
          <FormLabel>Spelling</FormLabel>
          <Input
            {...register('spelling', {
              required: 'You must enter a spelling',
              pattern: {
                value: /^[a-zA-Z.\-'']+$/,
                message:
                  'Please only include letters, full stops, hyphens or apostrophes',
              },
            })}
            autoCapitalize={'none'}
          />
          <FormErrorMessage>{errors.spelling?.message}</FormErrorMessage>
        </FormControl>
        <FormControl
          {...register('transcription', {
            required: 'You must enter a transcription',
            validate: (value: string) =>
              JSON.parse(value).length > 0 || 'Please add a transcription',
          })}
          id="transcription"
          isInvalid={errors.transcription !== undefined}
        >
          <FormLabel>
            Transcription
            <InfoPopover
              title="Multi-syllable Words"
              message="For multi-syllable words, select the sound at the end of each syllable, highlighting it green. The word 'apple' would look like:"
              image={apple_transcription_example}
            />
          </FormLabel>
          <Controller
            control={control}
            name="transcription"
            render={({ field }) => (
              <TranscriptionInput
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <FormErrorMessage>{errors.transcription?.message}</FormErrorMessage>
        </FormControl>
        <HStack justify={'center'}>
          <Button variant="grey" size="lg" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            loadingText="Saving"
            variant="info"
            size="lg"
            type="submit"
          >
            Save
          </Button>
        </HStack>
      </VStack>
    </Form>
  );
};

export default WordForm;
