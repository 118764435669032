import { VStack, HStack, Box, Divider } from '@chakra-ui/react';
import React, { FC } from 'react';
import { TranscriptionKey } from './TranscriptionKey';

export interface TranscriptionKeyboardLayoutProps {
  onAddSound: (sound: string) => void;
  onRemoveSound: () => void;
  screenWidth: number;
}

const CombinedLayout: FC<TranscriptionKeyboardLayoutProps> = ({
  onAddSound,
  onRemoveSound,
  screenWidth,
}) => {
  const keyWidth = Math.round(screenWidth / 16);
  const nz_vowels = {
    a1: ['i', 'u', 'ʊ'],
    a2: ['e', 'ɜ', 'ɔ'],
    a3: ['æ', 'ɪ', 'ə', 'ɒ'],
    a4: ['a', 'ʌ'],
    a5: ['ei', 'ai', 'ɔi'],
    a6: ['oʊ', 'aʊ'],
  };

  const consonants = {
    nasals: ['m', 'n', 'ŋ'],
    stops: ['p', 'b', 't', 'd', 'k', 'g', 'ʔ'],
    fricatives: ['θ', 'ð', 'f', 'v', 's', 'z', 'ʃ', 'ʒ', 'h'],
    affricates: ['ʧ', 'ʤ'],
    liquids: ['l', 'r', 'ɚ'],
    glides: ['w', 'j'],
  };
  return (
    <VStack pb={5}>
      <HStack height={{ base: '40px', lg: '66px' }}>
        <Box>
          {consonants.stops.map((v) => (
            <TranscriptionKey
              key={v}
              sound={v}
              onSelected={() => onAddSound(v)}
              keyWidth={keyWidth}
              color={'#F3861A'}
            />
          ))}
        </Box>
        <Box>
          {nz_vowels.a1.map((v) => (
            <TranscriptionKey
              key={v}
              sound={v}
              onSelected={() => onAddSound(v)}
              keyWidth={keyWidth}
            />
          ))}
        </Box>
          <TranscriptionKey
            key={'backspace'}
            isBackspace
            sound="⌫"
            keyWidth={keyWidth}
            onSelected={onRemoveSound}
          />
      </HStack>
      <HStack height={{ base: '40px', lg: '66px' }}>
        <Box>
          {consonants.fricatives.map((v) => (
            <TranscriptionKey
              key={v}
              sound={v}
              onSelected={() => onAddSound(v)}
              keyWidth={keyWidth}
              color={'#F3861A'}
            />
          ))}
        </Box>
        <Box>
          {nz_vowels.a2.map((v) => (
            <TranscriptionKey
              key={v}
              sound={v}
              onSelected={() => onAddSound(v)}
              keyWidth={keyWidth}
            />
          ))}
        </Box>
      </HStack>
      <HStack height={{ base: '40px', lg: '66px' }}>
        <Box>
          {consonants.nasals.concat(consonants.affricates).map((v) => (
            <TranscriptionKey
              key={v}
              sound={v}
              onSelected={() => onAddSound(v)}
              keyWidth={keyWidth}
              color={'#F3861A'}
            />
          ))}
        </Box>
        <Box>
          {nz_vowels.a3.concat(nz_vowels.a4).map((v) => (
            <TranscriptionKey
              key={v}
              sound={v}
              onSelected={() => onAddSound(v)}
              keyWidth={keyWidth}
            />
          ))}
        </Box>
      </HStack>
      <HStack height={{ base: '40px', lg: '66px' }}>
        <Box>
          {consonants.glides.concat(consonants.liquids).map((v) => (
            <TranscriptionKey
              key={v}
              sound={v}
              onSelected={() => onAddSound(v)}
              keyWidth={keyWidth}
              color={'#F3861A'}
            />
          ))}
        </Box>
        <Box>
          {nz_vowels.a5.concat(nz_vowels.a6).map((v) => (
            <TranscriptionKey
              key={v}
              sound={v}
              onSelected={() => onAddSound(v)}
              keyWidth={keyWidth}
            />
          ))}
        </Box>
      </HStack>
    </VStack>
  );
};

export default CombinedLayout;
