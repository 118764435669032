import { Box } from '@chakra-ui/react';
import React from 'react';

function NotFoundPage() {
  return (
    <Box pt={65}>
      <h2>Whoops! This page doesn't exist.</h2>;
    </Box>
  );
}

export default NotFoundPage;
