import React, { FC } from 'react';
import { useScreenWidth } from '../../utils/screenHooks';
import AssessmentListSmallScreen from './AssessmentListSmallScreen';
import AssessmentListLargeScreen from './AssessmentListLargeScreen';

const AssessmentList: FC = () => {
    const [orientation, screenWidth] = useScreenWidth();

    return (
        <>
            {screenWidth < 913 ?
                <AssessmentListSmallScreen />
                :
                <AssessmentListLargeScreen />
            }
        </>
    );
};

export default AssessmentList;
