import React from 'react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  PVMCategory,
  PVMConsonantTypes,
  PlaceVoiceManner,
} from '../../models/place-voice-manner';
import Consonant from './Consonant';

interface PVMTableProps {
  placeVoiceManner: PlaceVoiceManner;
  openCategories: Set<number>;
  consonantTypeResults: {
    [consonantCategory: string]: {
      totalCorrect: number;
      totalIncorrect: number;
    };
  };
  consonantTypes: PVMConsonantTypes;
}

const VerTable = ({
  consonantTypes,
  openCategories,
  consonantTypeResults,
  placeVoiceManner,
}: PVMTableProps) => {
  const totalCorrect = Object.entries(consonantTypeResults)
    .map((category) => {
      return category[1].totalCorrect;
    })
    .reduce((a, b) => a + b, 0);
  const totalIncorrect = Object.entries(consonantTypeResults)
    .map((category) => {
      return category[1].totalIncorrect;
    })
    .reduce((a, b) => a + b, 0);
  return (
    <>
      <Accordion allowMultiple defaultIndex={Array.from(openCategories)}>
        {Object.entries(consonantTypes).map((category, categoryIndex) => {
          const categoryName: PVMCategory = category[0] as PVMCategory;
          const totalCorrect = consonantTypeResults[categoryName].totalCorrect;
          const totalIncorrect =
            consonantTypeResults[categoryName].totalIncorrect;
          return (
            <AccordionItem key={categoryIndex}>
              <h2>
                <AccordionButton
                  bg={'gray.200'}
                  _expanded={{ bg: 'dark_shade' }}
                >
                  <Flex flex="1">
                    <Box textAlign="left" textTransform="capitalize">
                      {categoryName}
                    </Box>
                    <Spacer />
                    <Box textAlign="right">
                      {totalCorrect}{' '}
                      <CheckIcon color="success" boxSize={4} mr={3} mb={1} />
                      {totalIncorrect}{' '}
                      <CloseIcon color="error" boxSize={3} mr={3} mb={1} />
                    </Box>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel p={0}>
                <TableContainer>
                  <Table variant="simple" size="md">
                    <Thead>
                      <Tr>
                        <Th pl="4" pr="2">
                          Consonant
                        </Th>
                        <Th px="2">Initial</Th>
                        <Th px="2">Medial</Th>
                        <Th px="2">Final</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Consonant
                        placeVoiceManner={placeVoiceManner}
                        categoryName={categoryName}
                        sounds={category[1]}
                      />
                    </Tbody>
                  </Table>
                </TableContainer>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      <Box bg="dark_shade" pl="4" py="3" pr="9">
        <Flex flex="1">
          <Box textAlign="left" textTransform="capitalize">
            Total
          </Box>
          <Spacer minW="10px" />
          <Box textAlign="right">
            {totalCorrect}{' '}
            <CheckIcon color="success" boxSize={4} mr={3} mb={1} />
            {totalIncorrect}{' '}
            <CloseIcon color="error" boxSize={3} mr={3} mb={1} />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default VerTable;
