import React from "react";
import Features from "../components/Landing/Features";
import Header from "../components/Landing/Header";
import Footer from "../components/Landing/Footer";
import MailingList from "../components/Landing/MailingList";

function LandingPage() {
  return (
    <div>
      <Header />
      <Features />
      <MailingList />
      <Footer />
    </div>
  );
}

export default LandingPage;
