import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Input,
  UseDisclosureProps,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import Logo from '../../assests/logo';
import { useLogoutMutation } from '../../redux/apiSlice';
import { speechyApi } from '../../redux/apiSlice';
import { AppDispatch } from '../../redux/store';

const NavigationDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const dispatch = useDispatch();
  const { resetApiState } = speechyApi.util;
  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    await logout().unwrap();
    dispatch(resetApiState());
    history.push('/');
    onClose();
  };

  const gotoClients = () => {
    history.push('/clients');
    onClose();
  };

  const gotoWords = () => {
    history.push('/words');
    onClose();
  };

  const gotoLists = () => {
    history.push('/lists');
    onClose();
  };

  return (
    <>
      <IconButton
        bg="navbar"
        fontSize={'2xl'}
        icon={<HamburgerIcon />}
        aria-label={'Open Menu'}
        onClick={isOpen ? onClose : onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        // finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Center>
              <Logo />
            </Center>
          </DrawerHeader>

          <DrawerBody>
            <VStack>
              <Button onClick={gotoClients} variant={'primary'}>
                Clients
              </Button>
              <Button onClick={gotoWords} variant={'primary'}>
                Assessment Words
              </Button>
              <Button onClick={gotoLists} variant={'primary'}>
                Assessment Lists
              </Button>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant={'secondary'} onClick={handleLogout}>
              Logout
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default NavigationDrawer;
