import { SmallCloseIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import React, { FC } from 'react';

interface SearchFilterProps {
  filter: string;
  setFilter: (val: string) => void;
}
const SearchFilter: FC<SearchFilterProps> = ({ filter, setFilter }) => {
  const handleFilterChange = (e: any) => setFilter(e.target.value);

  return (
    <InputGroup maxW={'200px'}>
      <Input
        placeholder="Filter"
        value={filter}
        onChange={handleFilterChange}
      />
      {filter.length > 0 ? (
        <InputRightElement
          children={
            <IconButton
              aria-label="clear filter"
              size={'xs'}
              variant="grey"
              onClick={() => setFilter('')}
              paddingX={'40px'}
              icon={<SmallCloseIcon />}
            />
          }
        />
      ) : null}
    </InputGroup>
  );
};

export default SearchFilter;
