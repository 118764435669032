export default function getPercentage(
  partialValue: number,
  totalValue: number
): string {
  if (totalValue === 0) {
    return "0%";
  }

  return `${Math.round((partialValue / totalValue) * 100)}%`;
}
