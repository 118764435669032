import { PayloadAction, SerializedError, createSlice } from '@reduxjs/toolkit';
import { selectedTranscription } from '../../components/Words/WordTable';
import { Word } from '../../models/word';
import { speechyApi } from '../apiSlice';

export interface ListBuilderState {
  list_id?: number;
  name: string;
  isEditingName: boolean;
  list: Word[];
  error?: SerializedError;
}

const initialState: ListBuilderState = {
  list_id: undefined,
  name: '',
  isEditingName: true,
  list: [],
  error: undefined,
};

export const listBuilderSlice = createSlice({
  name: 'listBuilder',
  initialState,
  reducers: {
    setBuilderList(state, action: PayloadAction<Word[]>) {
      state.list = action.payload;
    },
    setBuilderListName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setEditingBuilderListName(state, action: PayloadAction<boolean>) {
      state.isEditingName = action.payload;
    },
    setBuilderListId(state, action: PayloadAction<number | undefined>) {
      state.list_id = action.payload;
    },
    addToBuilderList(state, action: PayloadAction<Word>) {
      // Ensure there is no double up of words
      state.list = state.list.filter(
        (w) => w.word_id !== action.payload.word_id,
      );
      state.list.push(action.payload);
    },
    removeFromBuilderList(state, action: PayloadAction<Word>) {
      state.list = state.list.filter(
        (l) =>
          !action.payload.transcriptions
            .map((t) => t.transcription_id)
            .includes(selectedTranscription(l).transcription_id),
      );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(speechyApi.endpoints.logout.matchFulfilled, (state) => {
      state.list_id = undefined;
      state.list = [];
      state.name = '';
      state.isEditingName = true;
    });
    builder.addMatcher(speechyApi.endpoints.logout.matchRejected, (state) => {
      state.list_id = undefined;
      state.list = [];
      state.name = '';
      state.isEditingName = true;
    });
  },
});

export const {
  setBuilderList,
  setBuilderListName,
  setEditingBuilderListName,
  setBuilderListId,
  addToBuilderList,
  removeFromBuilderList,
} = listBuilderSlice.actions;
