import { CheckIcon, EditIcon } from '@chakra-ui/icons';
import { Heading, HStack, IconButton, Input } from '@chakra-ui/react'
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBuilderListName, setEditingBuilderListName } from '../../redux/slices/listBuilderSlice';
import { AppDispatch, RootState } from '../../redux/store';
import InfoPopover from '../InfoPopover';

const ListBuilderName: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const listName = useSelector((state: RootState) => state.listBuilder.name)
    const editingName = useSelector((state: RootState) => state.listBuilder.isEditingName)

    const handleListNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setBuilderListName(event.target.value))
    }

    return (
        <HStack justify={'space-around'}>
            <InfoPopover
                title="Customizing Lists"
                message="You can reorder this list by dragging words into the desired order. Any new assessment words added from this page will automatically be added into this assessment list."
                placement="bottom-end"
            />
            {editingName ? (
                <Input
                    value={listName}
                    onChange={handleListNameChange}
                    placeholder="Assessment list name"
                ></Input>
            ) : (
                <Heading size={{ base: 'md', md: 'lg' }} variant={'primary'}>{listName}</Heading>
            )}
            <IconButton
                onClick={() => dispatch(setEditingBuilderListName(!editingName))}
                variant="secondary"
                icon={editingName ? <CheckIcon /> : <EditIcon />}
                aria-label="Edit list name"
                size={'sm'}
            />
        </HStack>
    )
}

export default ListBuilderName;
