import React from "react";

function Footer() {
  return (
    <footer className="bg-dark text-center text-white">
      <div className="text-center p-3">
        © {new Date().getFullYear()} Copyright University of Canterbury
      </div>
    </footer>
  );
}

export default Footer;
