import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@chakra-ui/react';
import PVM from '../components/PVM';
import PatternAnalysis from '../components/Results/PatternAnalysis';
import TestOverview from '../components/Results/TestOverview';
import { PatternAnalysisApiResponse } from '../models/error-pattern-analysis';
import { PVMCategory, PlaceVoiceManner } from '../models/place-voice-manner';
import { useFetchResultQuery } from '../redux/apiSlice';

interface TestResult {
  placeVoiceManner: PlaceVoiceManner;
  patternAnalysis: PatternAnalysisApiResponse;
}

const consonantTypes = {
  nasals: ['m', 'n', 'ŋ'],
  stops: ['p', 'b', 't', 'd', 'k', 'g'],
  fricatives: ['θ', 'ð', 'f', 'v', 's', 'z', 'ʃ', 'ʒ', 'h'], // "ɬ", "ɮ" removed to not show in PVM
  affricates: ['ʧ', 'ʤ'],
  liquids: ['l', 'r', 'ɚ'], // 'ɚ' is included in PVM as "consonant"
  glides: ['w', 'j'],
};

const ResultPage = () => {
  const { clientId, resultId } = useParams<{
    clientId: string;
    resultId: string;
  }>();

  const history = useHistory();
  const [result, setResult] = useState<null | TestResult>(null);
  const { data, isLoading } = useFetchResultQuery({
    clientId: parseInt(clientId),
    resultId: parseInt(resultId),
  });

  useEffect(() => {
    if (data) setResult(data);
  }, [data]);

  const openCategories = new Set<number>();
  const consonantTypeResults = Object.assign(
    {},
    ...Object.entries(consonantTypes).map((category, index) => {
      const sounds = category[1];
      let totalCorrect = 0;
      let totalIncorrect = 0;
      sounds.forEach((sound) => {
        const categoryName: PVMCategory = category[0] as PVMCategory;
        if (!result) return;

        const results = result.placeVoiceManner[categoryName];
        if (!Object.keys(results).includes(sound)) return;
        const consonant = results[sound];
        for (const place of [
          consonant.initial_consonant,
          consonant.medial_consonant,
          consonant.final_consonant,
        ]) {
          totalCorrect += place.num_correct;
          // eslint-disable-next-line
          Object.entries(place.errors).forEach((error) => {
            totalIncorrect += error[1];
          });
        }
      });
      if (totalIncorrect > 0) {
        openCategories.add(index);
      }
      return { [category[0]]: { totalCorrect, totalIncorrect } };
    }),
  );

  const editResults = () => {
    history.push(`/analysis/${clientId}/result/${resultId}`);
  };

  return (
    <Box pt={65}>
      {result !== null && (
        <>
          <PVM
            placeVoiceManner={result.placeVoiceManner}
            consonantTypeResults={consonantTypeResults}
            consonantTypes={consonantTypes}
            openCategories={openCategories}
          />
          <PatternAnalysis patternAnalysis={result.patternAnalysis} />
          <TestOverview consonantTypeResults={consonantTypeResults} />
          <Box p="8">
            <Button onClick={editResults} variant="info">
              Edit this assessment
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ResultPage;
