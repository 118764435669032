import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { ListWord } from '../../models/word';
import { useFetchListQuery } from '../../redux/apiSlice';
import getTranscription from '../../utils/getTranscription';

export interface WordMenuOption {
  label: string;
  onSelect: (word: ListWord) => void;
  icon: JSX.Element;
  variant?: string;
}

interface ListWordTableProps {
  list_id: number;
}

const ListWordTable: FC<ListWordTableProps> = ({ list_id }) => {
  const history = useHistory();
  const [words, setWords] = useState<ListWord[]>([]);
  const { data, isLoading } = useFetchListQuery(list_id);

  useEffect(() => {
    if (data) setWords(data.words);
  }, [data]);

  return (
    <TableContainer>
      <Table size="md" variant={'striped'} colorScheme="orange">
        <Thead>
          <Tr>
            <Th>
              <Flex h="10">
                <Center>Word</Center>
              </Flex>
            </Th>
            <Th>
              <Flex h="10" justifyContent={'space-between'}>
                <Center>Transcription</Center>
                <IconButton
                  onClick={() => history.push(`/lists/${list_id}`)}
                  variant={'secondary'}
                  icon={<EditIcon />}
                  aria-label={'Edit'}
                  size={'md'}
                />
              </Flex>
            </Th>
          </Tr>
        </Thead>
        {isLoading && (
          <TableCaption>
            <Spinner size="xl" />
          </TableCaption>
        )}
        <Tbody>
          {words?.map((w) => (
            <Tr key={w.word_id}>
              <Td>{w.word}</Td>
              <Td>{getTranscription(w.transcription, w.syllable_position)}</Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Total Words</Th>
            <Th isNumeric>{words.length}</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default ListWordTable;
