import { VStack, HStack, Box, Divider, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { TranscriptionKeyboardLayoutProps } from './CombinedLayout';
import { TranscriptionKey } from './TranscriptionKey';

const VowelLayout: FC<TranscriptionKeyboardLayoutProps> = ({
    onAddSound,
    onRemoveSound,
    screenWidth,
}) => {
    const keyWidth = Math.round(screenWidth / 9);
    const nz_vowels = {
        a1: ['i', 'u', 'ʊ'],
        a2: ['e', 'ɜ', 'ɔ'],
        a3: ['æ', 'ɪ', 'ə', 'ɒ'],
        a4: ['a', 'ʌ'],
        a5: ['ei', 'ai', 'ɔi'],
        a6: ['oʊ', 'aʊ'],
    };

    const consonants = {
        nasals: ['m', 'n', 'ŋ'],
        stops: ['p', 'b', 't', 'd', 'k', 'g', 'ʔ'],
        fricatives: ['θ', 'ð', 'f', 'v', 's', 'z', 'ʃ', 'ʒ', 'h'],
        affricates: ['ʧ', 'ʤ'],
        liquids: ['l', 'r', 'ɚ'],
        glides: ['w', 'j'],
    };
    return (
        <Flex>
            <VStack flex={9} pb={5} h={290}>
                <Box>
                    {nz_vowels.a1.map((v) => (
                        <TranscriptionKey
                            key={v}
                            keyWidth={keyWidth}
                            sound={v}
                            onSelected={() => onAddSound(v)}
                        />
                    ))}
                </Box>
                <Box>
                    {nz_vowels.a2.map((v) => (
                        <TranscriptionKey
                            key={v}
                            keyWidth={keyWidth}
                            sound={v}
                            onSelected={() => onAddSound(v)}
                        />
                    ))}
                </Box>
                <Box>
                    {nz_vowels.a3.concat(nz_vowels.a4).map((v) => (
                        <TranscriptionKey
                            key={v}
                            keyWidth={keyWidth}
                            sound={v}
                            onSelected={() => onAddSound(v)}
                        />
                    ))}
                </Box>
                <Box>
                    {nz_vowels.a5.concat(nz_vowels.a6).map((v) => (
                        <TranscriptionKey
                            key={v}
                            keyWidth={keyWidth}
                            sound={v}
                            onSelected={() => onAddSound(v)}
                        />
                    ))}
                </Box>
                {/* <HStack height={{ base: '40px', lg: '66px' }}>
              <Box>
              {nz_vowels.a3.map((v) => (
              <TranscriptionKey
              keyWidth={keyWidth}
              sound={v}
              onSelected={() => onAddSound(v)}
              />
              ))}
              </Box>
              <Divider orientation="vertical" variant={'keyboard'} />
              <Box>
              {nz_vowels.a4.map((v) => (
              <TranscriptionKey
              keyWidth={keyWidth}
              sound={v}
              onSelected={() => onAddSound(v)}
              />
              ))}
              </Box>
              </HStack>
              <HStack height={{ base: '40px', lg: '66px' }}>
              <Box>
              {nz_vowels.a5.map((v) => (
              <TranscriptionKey
              keyWidth={keyWidth}
              sound={v}
              onSelected={() => onAddSound(v)}
              />
              ))}
              </Box>
              <Divider orientation="vertical" variant={'keyboard'} />
              <Box>
              {nz_vowels.a6.map((v) => (
              <TranscriptionKey
              keyWidth={keyWidth}
              sound={v}
              onSelected={() => onAddSound(v)}
              />
              ))}
              </Box>
              </HStack> */}
            </VStack>
        </Flex>
    );
};

export default VowelLayout;
