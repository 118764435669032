import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, HStack, Text } from '@chakra-ui/react';
import { AppName } from '../speechy';

const Logo = () => {
  const history = useHistory();

  function homepage() {
    history.push('/');
  }

  return (
    <HStack px={'20px'}>
      <Text
        onClick={homepage}
        style={{ cursor: 'pointer' }}
        textColor="primary.500"
        fontSize={'3xl'}
        as="b"
      >
        {AppName}
      </Text>
      <Box position="relative" bottom={5} right={4}>
        <Text
          onClick={homepage}
          textColor="black"
          fontSize={'md'}
          as="b"
          cursor="pointer"
          position={'absolute'}
          transform="rotate(38deg)"
        >
          beta
        </Text>
      </Box>
    </HStack>
  );
};

export default Logo;
