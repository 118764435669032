import { InfoIcon, QuestionIcon } from '@chakra-ui/icons';
import {
  PopoverTrigger,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Button,
  Popover,
  Image,
  PopoverContent,
  IconButton,
  PopoverFooter,
  PlacementWithLogical,
} from '@chakra-ui/react';
import React, { FC } from 'react';

interface InfoPopoverProps {
  title: string;
  message: string;
  placement?: PlacementWithLogical;
  image?: string;
}

const InfoPopover: FC<InfoPopoverProps> = ({
  message,
  title,
  placement,
  image,
}) => {
  return (
    <Popover placement={placement ?? 'right'}>
      <PopoverTrigger>
        <IconButton
          ml={3}
          size="xs"
          background="transparent"
          color={'grey'}
          borderRadius={'3xl'}
          aria-label="info"
          icon={<InfoIcon />}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight={'bold'}>{title}</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody fontWeight={'normal'}>{message}</PopoverBody>
        {image && (
          <PopoverFooter>
            <Image
              rounded={'md'}
              alt={'Info image'}
              src={image}
              objectFit={'cover'}
            />
          </PopoverFooter>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default InfoPopover;
