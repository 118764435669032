import React, { FC } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Heading,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import WordForm from './WordForm';

interface AddTanscriptionModalProps {
  spellingInput: string;
}

const AddTranscriptionModal: FC<AddTanscriptionModalProps> = ({
  spellingInput,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem icon={<AddIcon />} onClick={onOpen}>
        New Transcription
      </MenuItem>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW={'fit-content'} height={'fit-content'}>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Flex justify="center">
              <Heading fontSize={'4xl'}>Add assessment word</Heading>
            </Flex>
            <WordForm onClose={onClose} spellingInput={spellingInput} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddTranscriptionModal;
