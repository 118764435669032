import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AssessmentList from '../components/Lists/AssessmentList';
import { Word } from '../models/word';
import { useFetchListQuery, useFetchWordsQuery } from '../redux/apiSlice';
import {
  setBuilderList,
  setBuilderListId,
  setBuilderListName,
  setEditingBuilderListName,
} from '../redux/slices/listBuilderSlice';
import { AppDispatch } from '../redux/store';

export interface TranscriptionSound {
  index: number;
  sound: string;
  syllableStart: boolean;
}

const EditListPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { listId } = useParams<{ listId: string }>();
  const {
    data: list,
    error: listError,
    isLoading: listIsLoading,
  } = useFetchListQuery(Number(listId));
  const {
    data: words,
    error: wordsError,
    isLoading: wordsIsLoading,
  } = useFetchWordsQuery();
  // Used to ensure builder list doesn't get reset when addAssessmentWord mutation fires
  const [hasSetInitialWords, setHasSetInitialWords] = useState(false);

  useEffect(() => {
    if (list && words && !hasSetInitialWords) {
      const IdMap = new Map();
      list.words.forEach((l) => IdMap.set(l.word_id, l.transcription_id));
      const listWords: Word[] = [];
      IdMap.forEach((transcription_id, word_id) => {
        const foundWord = words.find((w) => w.word_id === word_id);

        if (foundWord)
          listWords.push({
            ...foundWord,
            selected_transcription: foundWord.transcriptions.findIndex(
              (t) => t.transcription_id === transcription_id,
            ),
          });
      });
      dispatch(setBuilderList(listWords));
      dispatch(setBuilderListName(list.name));
      dispatch(setEditingBuilderListName(false));
      dispatch(setBuilderListId(list.list_id));
      setHasSetInitialWords(true);
    }
    if (listError) console.log(listError);
    if (wordsError) console.log(wordsError);
  }, [words, list]);

  return <AssessmentList />;
};

export default EditListPage;
