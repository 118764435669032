import {useState, useEffect} from 'react';

// Helper function to get the current orientation
function getOrientation() {
return window.matchMedia('(orientation: portrait)').matches
    ? 'portrait'
    : 'landscape';
}

export function useScreenOrientation(){
  const [orientation, setOrientation] = useState(getOrientation());

    // Function to update orientation when the device orientation changes
    const handleOrientationChange = () => {
      setOrientation(getOrientation());
    };

  useEffect(() => {
    // Add event listener to media query for orientation change
    const mediaQueryList = window.matchMedia('(orientation: portrait)');
    mediaQueryList.addListener(handleOrientationChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      mediaQueryList.removeListener(handleOrientationChange);
    };
  }, []);

    return orientation;
}

export function useScreenWidth(): [string, number]{
  const [orientation, setOrientation] = useState(getOrientation());
  const [screenWidth, setScreenWidth] = useState(Math.round(window.innerWidth));
  let horWidth = Math.round(window.innerWidth);
  let verWidth = Math.round(window.innerHeight);
  if (horWidth < verWidth) {
    verWidth = horWidth;
    horWidth = Math.round(window.innerHeight);
  }

    // Function to update orientation when the device orientation changes
    const handleOrientationChange = () => {
      if (getOrientation() == 'portrait') setScreenWidth(verWidth);
      else setScreenWidth(horWidth);
      setOrientation(getOrientation());
    };

  useEffect(() => {
    // Add event listener to media query for orientation change
    const mediaQueryList = window.matchMedia('(orientation: portrait)');
    mediaQueryList.addListener(handleOrientationChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      mediaQueryList.removeListener(handleOrientationChange);
    };
  }, []);

    return [ orientation, screenWidth ];
}
