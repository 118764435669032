import { createStandaloneToast } from '@chakra-ui/react';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

/**
 * Log a warning!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    const { ToastContainer, toast } = createStandaloneToast();
    if (isRejectedWithValue(action)) {
      console.warn(action);
    }

    return next(action);
  };
