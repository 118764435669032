import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import AssessmentWordsSplit from './AssessmentWordsSplit';
import CustomListSplit from './CustomListSplit';

const AssessmentListLargeScreen: FC = () => {

    return (
        <Flex pt={65}>
            <AssessmentWordsSplit />
            <CustomListSplit />
        </Flex>
    );
};

export default AssessmentListLargeScreen;
