import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { use } from 'passport';

interface TranscriptionKeyProps {
  sound: string;
  onSelected: (selectedCharacter: string) => void;
  isBackspace?: boolean;
  keyWidth: number;
  color?: string;
}

export const TranscriptionKey = (props: TranscriptionKeyProps) => {
  const { onSelected, sound, isBackspace, keyWidth, color } = props;

  let keyColor = '#FDC287';
  if (color) keyColor = color

  const handleClick = () => {
    onSelected(sound);
  };

  return (
    <Button
      onClick={handleClick}
      borderRadius={'15px'}
      borderColor={isBackspace ? 'darkred' : '#8C4703'}
      borderWidth={'2px'}
      backgroundColor={isBackspace ? 'red.500' : keyColor}
      height={{ base: '45px', md: '45px', lg: '60px' }}
      width={isBackspace ? `${keyWidth * 1.5}px` : `${keyWidth}px`}
      // width={{
      //   base: keyWidth,
      //   // base: '20px',
      //   // md: isBackspace ? '70px' : '50px',
      //   // lg: isBackspace ? '100px' : '60px',
      // }}
      color={color || isBackspace ? 'white' : '#783D02'}
      margin={'3px'}
      fontSize={{ base: 'md', md: '2xl', lg: '3xl' }}
    >
      {sound}
    </Button>
  );
};
