import React, { useState } from "react";
import OtherSounds from "./OtherSoundsModal";
import { SoundButton } from "./SoundButton";

interface SoundProps { 
  selectedSound: string;
  correctSound: string;
  pos: number;
  onChange: (sound: string, pos: number) => void;
  isEmpty: () => boolean;
}

export const Sound = (props: SoundProps) => {
  const { selectedSound, correctSound, pos } = props;
  const [shouldDisplayModal, setShouldDisplayModal] = useState(false);

  const openModal = () => {
    setShouldDisplayModal(true);
  };
  const closeModal = () => setShouldDisplayModal(false);

  const onChange = (sound: string) => {
    props.onChange(sound, pos);
  };

  const getButtonColour = () => {
    if (selectedSound === "ø") {
      return "black";
    } else if (selectedSound === correctSound) {
      return "success";
    } else {
      return "error";
    }
  };

  return (
    <div className="col">
      <div className="row justify-content-center">
        <SoundButton
          selected={true}
          sound={selectedSound}
          onSelected={openModal}
          colour={getButtonColour()}
        />
        <OtherSounds
          correctSound={correctSound}
          show={shouldDisplayModal}
          close={closeModal}
          onSelected={onChange}
          selectedCharacter={selectedSound}
        />
      </div>
    </div>
  );
};
