import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { current } from '@reduxjs/toolkit';
import axios from '../../axios-config';
import Syllable from '../../components/Test/Syllable';
import { WordDropdown } from '../../components/Test/WordDropdown';
import { ListWord, TestWord } from '../../models/word';

interface AnalysisStepperProps {
  list_name: string;
  analysis_words: TestWord[];
  submitResults: (list_name: string, words: TestWord[]) => void;
}
const AnalysisStepper: FC<AnalysisStepperProps> = ({
  list_name,
  analysis_words,
  submitResults,
}) => {
  const [wordIndex, setWordIndex] = useState(0);
  const [words, setWords] = useState<TestWord[]>(analysis_words);
  const [word, setWord] = useState<TestWord>(analysis_words[0]);
  const [isMultiSyllableWord, setIsMultiSyllableWord] = useState<boolean>(
    analysis_words[0].correctSounds.length > 1,
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { listId, clientId } = useParams<{
    listId: string;
    clientId: string;
  }>();
  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    setWord(words[wordIndex]);
    setIsMultiSyllableWord(words[wordIndex].correctSounds.length > 1);
  }, [wordIndex]);

  const skipWord = () => {
    const word = words[wordIndex];
    word.beenTested = false;
    changeWord(true);
  };

  const nextWord = () => {
    const word = words[wordIndex];
    word.beenTested = true;
    changeWord(true);
  };

  const prevWord = () => {
    const word = words[wordIndex];
    word.beenTested = true;
    changeWord(false);
  };

  const changeWord = (moveForward: boolean) => {
    const currentIndex = wordIndex;
    if (moveForward) {
      if (currentIndex === words.length - 1) submitTest();
      else setWordIndex(currentIndex + 1);
    } else if (currentIndex > 0) setWordIndex(currentIndex - 1);
  };

  const submitTest = () => {
    const filteredWords = words.filter((w) => w.beenTested);
    if (filteredWords.length < 1)
      toast({ title: 'Please test at least one word', status: 'warning' });
    else submitResults(list_name, filteredWords);
  };

  const onSoundChange = (value: string[], pos: number) => {
    const newWords = [...words];
    const word = newWords[wordIndex];
    let selected = word.selectedSounds;
    // @ts-ignore
    selected[pos] = value;
    word.selectedSounds = selected;
    newWords[wordIndex] = word;
    setWords(newWords);
  };

  const onDropdownChange = (index: string | string[]) => {
    if (typeof index === 'string') setWordIndex(parseInt(index));
  };

  const navigationButtons = (
    <div className="align-content-center text-md-right text-center">
      <IconButton
        visibility={wordIndex >= 1 ? 'visible' : 'hidden'}
        className="mx-2"
        onClick={prevWord}
        variant="primary"
        isRound={true}
        icon={<ChevronLeftIcon />}
        aria-label="Previous WordDropdown"
        size={'lg'}
        fontSize="2.5em"
      />
      <Button size={'lg'} variant="grey" onClick={() => skipWord()}>
        Skip
      </Button>
      <IconButton
        className="mx-2"
        onClick={nextWord}
        variant="primary"
        isRound={true}
        icon={<ChevronRightIcon />}
        aria-label="Next WordDropdown"
        size={'lg'}
        fontSize="2.5em"
      />
    </div>
  );

  return (
    <>
      <HStack justifyContent={'space-around'}>
        <WordDropdown
          words={words}
          wordIndex={wordIndex}
          onChange={onDropdownChange}
        />
      </HStack>
      <Flex direction={'row'}>
        {word.correctSounds.map((syllable: string[], i: number) => (
          <Syllable
            key={i}
            syllable={syllable}
            position={i}
            isPartOfMultiSyllableWord={isMultiSyllableWord}
            onChange={onSoundChange}
            selected={word.selectedSounds[i]}
          />
        ))}
      </Flex>
      <HStack pos={'absolute'} bottom={10} w={'100%'} justifyContent={'center'}>
        {navigationButtons}
      </HStack>
    </>
  );
};

export default AnalysisStepper;
