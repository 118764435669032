import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { speechyApi } from './apiSlice';
import { rtkQueryErrorLogger } from './errorMiddleware';
import { authSlice } from './slices/auth';
import { listBuilderSlice } from './slices/listBuilderSlice';

const reducers = combineReducers({
  auth: authSlice.reducer,
  listBuilder: listBuilderSlice.reducer,
  [speechyApi.reducerPath]: speechyApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(thunkMiddleware)
      .concat(speechyApi.middleware)
      .concat(rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
