import React from "react";
import {Alert, AlertIcon, Stack, Flex} from "@chakra-ui/react";

function UnderMaintenancePage() {
  return (
    <Flex justify="center" align="center" minH="95vh" direction="column">
      <Stack
        textAlign={"center"}
        maxW="3xl"
        align={"center"}
        spacing={{ base: 5, md: 8 }}
      >
        <Alert status="info" maxW={"xl"}>
          <AlertIcon />
          Speechy is currently under going maintenance. Please check back
          shortly
        </Alert>
      </Stack>
    </Flex>
  );
}

export default UnderMaintenancePage;
