import React from "react";
import Correct from "./Correct";
import { PVMConsonantPlace } from "../../models/place-voice-manner";
import Error from "./Error";

interface ConsonantPlaceProps {
  consonant: PVMConsonantPlace;
}

const ConsonantPlace = ({ consonant }: ConsonantPlaceProps) => {
  let correctDisplay = null;
  let incorrectDisplay = null;

  const { num_correct, errors } = consonant;
  correctDisplay = <Correct count={num_correct} />;
  incorrectDisplay = Object.entries(errors).map(
    ([incorrectSound, incorrectSoundCount]) => (
      <Error
        sound={incorrectSound}
        count={incorrectSoundCount}
        key={incorrectSound + incorrectSoundCount}
      />
    )
  );

  return (
    <>
      <span>{correctDisplay}</span>
      <span>{incorrectDisplay}</span>
    </>
  );
};
export default ConsonantPlace;
