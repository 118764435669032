import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Box, Heading, VStack } from '@chakra-ui/react';
import { Word } from '../../models/word';
import { useFetchWordsQuery } from '../../redux/apiSlice';
import {
  addToBuilderList,
  removeFromBuilderList,
} from '../../redux/slices/listBuilderSlice';
import { AppDispatch, RootState } from '../../redux/store';
import SearchFilter from '../SearchFilter';
import AddWordModal from '../Words/AddWordModal';
import WordTable, {
  WordMenuOption,
  selectedTranscription,
} from '../Words/WordTable';

const AssessmentWordsTab: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [filter, setFilter] = useState('');
  const [words, setWords] = useState<Word[]>([]);
  const { data, isLoading } = useFetchWordsQuery();

  const editingListIDs = useSelector(
    (state: RootState) => state.listBuilder.list,
  ).map((l) => l.word_id);

  const addWordMenuOption: WordMenuOption = {
    label: 'Add to list',
    onSelect: (word: Word) => {
      console.log(word);
      dispatch(addToBuilderList(word));
    },
    icon: <AddIcon />,
    variant: 'success',
  };

  const removeWordMenuOption: WordMenuOption = {
    label: 'Remove from list',
    onSelect: (word: Word) => {
      console.log(word);
      dispatch(removeFromBuilderList(word));
    },
    icon: <MinusIcon />,
    variant: 'warning',
  };

  const menuOptions = [addWordMenuOption, removeWordMenuOption];

  useEffect(() => {
    if (data) setWords(data);
  }, [data]);
  return (
    <>
      <VStack py={{ base: '150px', md: '160px' }}>
        {/* Assessment word table */}
        <WordTable
          words={words}
          filter={filter}
          setWords={setWords}
          loading={isLoading}
          droppableId="words"
          menuOptions={menuOptions}
          height={'calc(100vh - 340px)'}
          editingListIDs={editingListIDs}
        />
        {/* Heading and search bar */}
        <VStack
          bg="background"
          w="100%"
          alignItems={'center'}
          pos="fixed"
          top={'130px'}
          boxShadow="md"
          py="10px"
        >
          <Heading size={{ base: 'md', md: 'lg' }} variant={'primary'}>
            Assessment Words
          </Heading>
          <SearchFilter filter={filter} setFilter={setFilter} />
        </VStack>
        {/* Add Word button/modal */}
        <Box
          pos="fixed"
          bottom="32px"
          right={{ base: '32px', lg: '10vw', xl: '20vw' }}
        >
          <AddWordModal />
        </Box>
      </VStack>
    </>
  );
};

export default AssessmentWordsTab;
