import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import camelCase from 'camelcase-keys';
import ClientContactDetails from '../components/Client/ClientContactDetails';
import ResultOverview from '../components/Client/ResultOverview';
import Client from '../models/client';
import { ListSummary } from '../models/list';
import {
  useFetchClientQuery,
  useFetchListSummariesQuery,
} from '../redux/apiSlice';

const ClientPage = () => {
  const { id } = useParams<{ id: string }>();
  const [client, setClient] = useState<Client | null>(null);
  const [lists, setLists] = useState<ListSummary[]>([]);
  const history = useHistory();
  const { data: clientData, isLoading: clientIsLoading } = useFetchClientQuery(
    parseInt(id),
  );
  const { data: listsData, isLoading: listsIsLoading } =
    useFetchListSummariesQuery();

  useEffect(() => {
    if (clientData) setClient(camelCase(clientData));
    if (listsData) setLists(listsData);
  }, [clientData, listsData]);

  const clientDisplay = client ? (
    <ClientContactDetails client={client} />
  ) : null;

  return (
    <Flex
      direction="column"
      minH={'94vh'}
      align={'center'}
      justify={'center'}
      pt={65}
    >
      {clientDisplay}
      {client?.results?.map((result) => (
        <ResultOverview key={result.id} result={result} clientId={client.id} />
      ))}
      {client && (
        <>
          <Menu>
            <MenuButton as={Button} variant="primary" px="8" m="8">
              New test
            </MenuButton>
            <MenuList>
              {lists.map((l) => (
                <MenuItem
                  key={l.list_id}
                  onClick={() =>
                    history.push(`/analysis/${client.id}/list/${l.list_id}`)
                  }
                >
                  {l.name}
                </MenuItem>
              ))}
              <MenuItem
                icon={<AddIcon />}
                onClick={() => history.push('/addlist')}
              >
                New Assessment List
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      )}
    </Flex>
  );
};

export default ClientPage;
