import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
} from '@chakra-ui/react';
import { Word } from '../../models/word';
import { useFetchWordsQuery } from '../../redux/apiSlice';
import {
  addToBuilderList,
  removeFromBuilderList,
} from '../../redux/slices/listBuilderSlice';
import { AppDispatch, RootState } from '../../redux/store';
import SearchFilter from '../SearchFilter';
import AddWordModal from '../Words/AddWordModal';
import WordTable, { WordMenuOption } from '../Words/WordTable';

const AssessmentWordsSplit: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [filter, setFilter] = useState('');
  const [words, setWords] = useState<Word[]>([]);
  const { data, isLoading } = useFetchWordsQuery();
  const editingListIDs = useSelector(
    (state: RootState) => state.listBuilder.list,
  ).map((l) => l.word_id);

  const addWordMenuOption: WordMenuOption = {
    label: 'Add to list',
    onSelect: (word: Word) => dispatch(addToBuilderList(word)),
    icon: <AddIcon />,
    variant: 'success',
  };

  const removeWordMenuOption: WordMenuOption = {
    label: 'Remove from list',
    onSelect: (word: Word) => dispatch(removeFromBuilderList(word)),
    icon: <MinusIcon />,
    variant: 'warning',
  };

  const menuOptions = [addWordMenuOption, removeWordMenuOption];

  useEffect(() => {
    if (data) setWords(data);
  }, [data]);
  return (
    <>
      <Card
        alignItems="center"
        h="100%"
        maxH={'calc(100vh - 90px)'}
        width={'calc(50vw - 15px)'}
        ml={'15px'}
        variant={'primary'}
      >
        <CardHeader>
          {/* Heading and search bar */}
          <Heading size={{ base: 'md', md: 'lg' }} variant={'primary'}>
            Assessment Words
          </Heading>
          <SearchFilter filter={filter} setFilter={setFilter} />
        </CardHeader>

        <CardBody w="100%">
          {/* Assessment word table */}
          <WordTable
            words={words}
            filter={filter}
            setWords={setWords}
            loading={isLoading}
            droppableId="words"
            menuOptions={menuOptions}
            height={'calc(100vh - 340px)'}
            editingListIDs={editingListIDs}
          />
        </CardBody>

        <CardFooter>
          <AddWordModal />
        </CardFooter>
      </Card>
    </>
  );
};

export default AssessmentWordsSplit;
